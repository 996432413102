import OneSignal from "react-onesignal";
import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
}

export class NotificationProvider extends React.PureComponent<Props> {
  componentDidMount() {
    OneSignal.init({appId: 'a1133359-fd94-4687-93e8-67af0bc13811'}).then(this.handleInitialized).catch()
    this.setState({isInitialized: true})
  }

  async handleInitialized() {
    await OneSignal.Slidedown.promptPush()
  }

  render() {
    return this.props.children;
  }
}