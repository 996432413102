import React from 'react'
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface Props {
  notWorking: boolean
  toggleWorking: () => void
}

export const NotWorkingSwitch = ({notWorking, toggleWorking}: Props) => {
  const color = notWorking ? "var(--falcon-primary)" : "var(--falcon-400)";
  const icon: any = notWorking ? ["fas", "square-check"] : ["far", "square"];

  return (
    <StyledContainer>
      <StyledLabel>
        <FormattedMessage id="work.schedule.upsert.set.as.non.working"/>
      </StyledLabel>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        fontSize="1.5em"
        onClick={toggleWorking}
        className="cursor-pointer pt-1"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  align-items: center;
  justify-content: space-between;
`

const StyledLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 3px;
  padding-right: .5rem;
`