import { gql, useMutation } from "@apollo/client";
import { IUpdateWorkScheduleRequest, IUpdateWorkScheduleResponse } from "../Types";

export const UPDATE_WORK_SCHEDULE_MUTATION = gql`
    mutation UpdateWOrkScheduleMutation( $workSchedule: UpdateWorkScheduleCommandRequestInput!) {
        updateWorkSchedule(input: { workSchedule: $workSchedule }) {
            partnersGetDayWorkSchedulePayload {
                response {
                    date
                    endTime
                    startTime
                    employeeId
                    breaks {
                        breakEnd
                        duration
                        breakStart
                    }
                }
            }
        }
    }
`

/**
 * Use send notification mutation
 */
export const useUpdateWorkScheduleMutation = () =>
  useMutation<IUpdateWorkScheduleResponse, IUpdateWorkScheduleRequest>(
    UPDATE_WORK_SCHEDULE_MUTATION, {
      fetchPolicy: 'network-only',
    });