import React from 'react'
import cn from 'classnames';
import { useIntl } from "react-intl";
import { getIn, useField, useFormikContext } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Col, Form, FormControlProps, InputGroup } from "react-bootstrap";
import styled from "styled-components";

interface Props extends FormControlProps {
  id: string
  name: string
  label?: string
  icon?: IconProp
}

export const InputField = ({name, id, label, icon, ...rest}: Props) => {
  const intl = useIntl()
  const [field, meta] = useField(name)
  const {setFieldTouched, setFieldValue, errors} = useFormikContext()

  async function handleFocusAsync() {
    await setFieldTouched(field.name, true)
  }

  function shouldMarkAsError(): boolean {
    return meta.touched && !!meta.error
  }

  function getTranslatableError(): string | undefined {
    const error = getIn(errors, field.name)
    return !error?.key ? undefined : intl.formatMessage({id: error.key}, error.values)
  }

  async function handleChangeAsync(event: React.ChangeEvent<HTMLInputElement>) {
    await setFieldValue(field.name, event.target.value);
  }

  return (
    <Form.Group as={Col}>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <InputGroup>
        <Form.Control
          id={id}
          {...rest}
          name={name}
          onFocus={handleFocusAsync}
          onChange={handleChangeAsync}
          isInvalid={shouldMarkAsError()}
          className={cn("outline-none rounded-3 bg-100 mb-2", rest.className)}
        />
        <Form.Control.Feedback type="invalid" className="capitalize">{getTranslatableError()}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
}


const StyledLabel = styled(Form.Label)`
  font-size: .8em;
  color: var(--falcon-gray-500);
`