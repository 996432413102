import {gql, useMutation} from "@apollo/client";
import {IPartnerUpdateAppointmentRequest, IPartnerUpdateAppointmentResponse} from "../Types";

export const UPDATE_APPOINTMENT_MUTATION = gql`
    mutation UpdateAppointmentMutation($updateRequest: UpdateAppointmentCommandDtoInput!) {
        updateAppointment(input: {request: {input: $updateRequest}}) {
            partnerAppointmentPayload {
                response {
                    id
                }
            }
        }
    }
`

/**
 * Use send notification mutation
 */
export const useUpdateAppointmentMutation = () =>
  useMutation<IPartnerUpdateAppointmentResponse, IPartnerUpdateAppointmentRequest>(
    UPDATE_APPOINTMENT_MUTATION, {
      fetchPolicy: 'network-only',
    });