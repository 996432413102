import React from 'react'
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const LoadingIndicator = () => {
  return (
    <span className="d-flex align-items-center justify-content-center">
      <FormattedMessage id="common.form.element.please.wait"/>
      <StyledSpinner animation="grow" size="sm"/>
    </span>
  )
}

const StyledSpinner = styled(Spinner)`
  width: 1.1rem;
  height: 1.1rem;
  font-size: .80rem;
  margin-left: 1rem;
`