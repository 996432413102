import React from 'react'
import cn from "classnames";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalizationEnum } from "../../Cutlio.Providers/LocalizationProvider";

interface Props {
  currentLanguage: string
  localizedLangauge: string
  language: LocalizationEnum

  onLanguageItemClicked(langauge: LocalizationEnum): void
}

export const LanguageMenuItem = ({currentLanguage, language, localizedLangauge, onLanguageItemClicked}: Props) => {
  const isActive = currentLanguage === language;

  function handleItemClick() {
    onLanguageItemClicked(language)
  }

  return (
    <StyledItemHolder>
      <StyledDropdownItem className="link-600 fs-10" onClick={handleItemClick}>{localizedLangauge}</StyledDropdownItem>
      <StyledSelectedIcon icon="check" className={cn({'d-none': !isActive})}/>
    </StyledItemHolder>
  )
}

const StyledItemHolder = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-direction: row;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background: var(--falcon-white);
  justify-content: space-between;
  border-radius: var(--falcon-border-radius);
`

const StyledSelectedIcon = styled(FontAwesomeIcon)`
  top: 25%;
  right: 0.75rem;
  position: absolute;
  color: var(--falcon-gray-600);
`

const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  font-size: .833333333rem;
`