import { gql, useMutation } from "@apollo/client";
import { IPartnerCancelAppointmentRequest, IPartnerCancelAppointmentResponse } from "../Types";

export const CANCEL_APPOINTMENT_MUTATION = gql`
    mutation CancelAppointmentMutation($cancelRequest: CancelAppointmentCommandDtoInput!) {
        cancelAppointment(input: {request: {input: $cancelRequest}}) {
            partnerAppointmentPayload {
                response {
                    id
                }
            }
        }
    }
`

/**
 * Use send notification mutation
 */
export const useCancelAppointmentMutation = () =>
  useMutation<IPartnerCancelAppointmentResponse, IPartnerCancelAppointmentRequest>(
    CANCEL_APPOINTMENT_MUTATION, {
      fetchPolicy: 'network-only',
    });