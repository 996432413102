import { PropsWithChildren } from "react";
import { ConfigProvider } from "react-avatar";
import { AVATAR_COLORS } from "../../Cutlio.Config/Theme/AvatarTheme";

interface Props extends PropsWithChildren {
}

export const AvatarProvider = ({children}: Props) => {
  // @ts-ignore
  return <ConfigProvider colors={AVATAR_COLORS}>{children}</ConfigProvider>
}