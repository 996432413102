import React, { useState } from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Offcanvas } from "react-bootstrap";
import { addYears, endOfWeek, format, parse, startOfWeek } from "date-fns";
import { IWorkScheduleBreakItem, IWorkScheduleBreakUpdateItem, IWorkScheduleDayResponse } from "../Types";
import { RepeatUntil } from "../Components/RepeatUntil";
import { IEmployeeListItem } from "../../Employees/Types";
import { WeekDayPicker } from '../Components/WeekDayPicker';
import { ISelectOption } from "../../../Cutlio.Types/Select";
import { CardLoader } from "../../../Cutlio.Common/CardLoader";
import { NotWorkingSwitch } from "../Components/NotWorkingSwitch";
import { ILocationWorkScheduleRequest, IWorkScheduleListItem } from "../../WorkSchedule/Types";
import { SidebarHeader } from "../../../Cutlio.Common/SidebarHeader";
import { useGetWorkSchedule } from "../Queries/GetWorkScheduleQuery";
import { SidebarAvatar } from "../../../Cutlio.Common/SidebarAvatar";
import { SidebarDivider } from "../../../Cutlio.Common/SidebarDivider";
import { TimeRangePicker } from "../../../Cutlio.Common/TimeRangePicker";
import { PrimaryButton } from "../../../Cutlio.Common/Buttons/PrimaryButton";
import { SidebarDatePicker } from "../../../Cutlio.Common/SidebarDatePicker";
import { CUTLIO_DATE_FORMAT, CUTLIO_DATE_TIME_FORMAT, CUTLIO_TIME_FORMAT } from "../../Application/Contants";
import { useUpdateWorkScheduleMutation } from "../Mutations/UpdateWorkScheduleMutation";
import { ICutlioError, useApolloError } from "../../../Cutlio.Common/Hooks/Error/ApolloNetworkError";
import { GET_LOCATION_WORK_SCHEDULE } from "../../WorkSchedule/Queries";
import { useCurrentLocation } from "../../../Cutlio.Providers/LocationsProvider/Context";

interface Props {
  show: boolean
  employee?: IEmployeeListItem | null
  schedule?: IWorkScheduleListItem | null;

  toggleSidebar(): void
}

interface State {
  endTime: string;
  employeeId: number
  startTime: string;
  notWorking: boolean;
  repeatEvery: number
  selectedDate: string;
  repeatToDate?: string
  selectedDays: Array<number>
  breaks: Array<IWorkScheduleBreakUpdateItem>
}

const DEFAULT_STATE: State = {
  breaks: [],
  employeeId: 0,
  repeatEvery: 0,
  selectedDays: [],
  endTime: "16:00",
  startTime: "09:00",
  notWorking: false,
  selectedDate: format(new Date(), CUTLIO_DATE_FORMAT),
  repeatToDate: format(addYears(new Date(), 1), CUTLIO_DATE_FORMAT),
}

export const WorkScheduleUpsertContainer = ({show, toggleSidebar, schedule, employee}: Props) => {
  const intl = useIntl()
  const date = schedule?.date!
  const employeeId = schedule?.employeeId || 0;
  const {currentLocation} = useCurrentLocation()
  const name = `${employee?.firstName} ${employee?.lastName}`
  const formattedNow = format(new Date(), CUTLIO_DATE_FORMAT);
  const [state, setState] = useState<State>(getInitialState())
  const {loading} = useGetWorkSchedule(employeeId, date, !show, onCompleted)

  const [updateSchedule, updateOptions] = useUpdateWorkScheduleMutation();
  const updateError: ICutlioError = useApolloError(updateOptions.error, updateOptions.loading)

  function onCompleted(data: IWorkScheduleDayResponse) {
    const breaks = data?.employeeDaySchedule?.response?.breaks || DEFAULT_STATE.breaks;
    const endTime: string | undefined = data?.employeeDaySchedule?.response?.endTime || DEFAULT_STATE.endTime;
    const startTime: string | undefined = data?.employeeDaySchedule?.response?.startTime || DEFAULT_STATE.startTime;
    setState({...state, selectedDate: date, startTime, endTime, employeeId: schedule!.employeeId, breaks: mapBreaks(breaks)})
  }

  function getInitialState(): State {
    const employeeId = schedule?.employeeId || 0
    return {...DEFAULT_STATE, employeeId: employeeId, selectedDate: date}
  }

  function mapBreaks(breaks: Array<IWorkScheduleBreakItem>): Array<IWorkScheduleBreakUpdateItem> {
    return breaks.map((item) => ({breakStart: item.breakStart, breakEnd: item.breakEnd, date: date}));
  }

  function handleAddPause() {
    const pause = {breakStart: "11:00", breakEnd: "12:00", date: state.selectedDate}
    setState({...state, breaks: [...state.breaks, pause]})
  }

  function parseEndTime(time: string | undefined) {
    const fullDateTime = getFullDateTime(time, DEFAULT_STATE.endTime)
    return parse(fullDateTime, CUTLIO_DATE_TIME_FORMAT, new Date())
  }

  function parseStartTime(time: string | undefined) {
    const fullDateTime = getFullDateTime(time, DEFAULT_STATE.startTime)
    return parse(fullDateTime, CUTLIO_DATE_TIME_FORMAT, new Date())
  }

  function getFullDateTime(value: string | undefined, defaultValue: string) {
    return `${formattedNow} ${value || defaultValue}`;
  }

  function toggleWorking() {
    setState({...state, notWorking: !state.notWorking})
  }

  function setRepeatEvery(option: ISelectOption<number> | null) {
    if (!option) return;
    setState({...state, repeatEvery: option.value})
  }

  function setRepeatTo(date: Date) {
    setState({...state, repeatToDate: format(date, CUTLIO_DATE_FORMAT)})
  }

  function handleSubmitAsync() {
    const newSchedule = state;
    const {locationId} = currentLocation
    const currentDate = parse(schedule!.date, CUTLIO_DATE_FORMAT, new Date())
    const endDate = format(endOfWeek(currentDate, {weekStartsOn: 1}), CUTLIO_DATE_FORMAT)
    const startDate = format(startOfWeek(currentDate, {weekStartsOn: 1}), CUTLIO_DATE_FORMAT)

    newSchedule.breaks.forEach(scheduleBreak => scheduleBreak.date = date);
    const variables: ILocationWorkScheduleRequest = {locationId, startDate, endDate, showFixedSchedule: false}

    updateSchedule({awaitRefetchQueries: true, variables: {workSchedule: newSchedule}, refetchQueries: [{query: GET_LOCATION_WORK_SCHEDULE, variables: variables}]})
      .then(handleSuccessUpdate)
      .catch(handleApolloError);
  }

  function handleSuccessUpdate() {
    setState({...state, ...DEFAULT_STATE})
    toggleSidebar()
  }

  function handleApolloError() {
    toggleSidebar()
  }

  function handleDateChange() {
  }

  function handleEndTimeChange(date: Date) {
    setState({...state, endTime: format(date, CUTLIO_TIME_FORMAT)})
  }

  function handleStartTimeChange(date: Date) {
    setState({...state, startTime: format(date, CUTLIO_TIME_FORMAT)})
  }

  function handleWeekDayItemClicked(day: number) {
    if (!state.selectedDays.includes(day)) setState({...state, selectedDays: [...state.selectedDays, day]})
    else setState({...state, selectedDays: [...state.selectedDays.filter(item => item !== day)]})
  }

  function isValid() {
    if (state.startTime >= state.endTime) return false;
    return !state.breaks.some(pause => pause.breakStart >= pause.breakEnd);
  }

  function removePauseFromBreaks(index: number | undefined) {
    const {breaks} = state;
    if (index === null) return;
    if (index === undefined) return;
    breaks.splice(index, 1);
    setState({...state, breaks: [...breaks]})
  }

  function handlePauseEndChanged(breakEndTime: Date, index?: number) {
    if (index === null) return;
    if (index === undefined) return;

    const {breaks} = state;
    const {breakStart, date} = breaks[index];
    const breakEnd = format(breakEndTime, CUTLIO_TIME_FORMAT);

    breaks[index] = {breakEnd: breakEnd, breakStart, date}
    setState({...state, breaks: [...breaks]})
  }

  function handlePauseStartChanged(breakStartTime: Date, index?: number) {
    if (index === null) return;
    if (index === undefined) return;

    const {breaks} = state;
    const {breakEnd, date} = breaks[index];
    const breakStart = format(breakStartTime, CUTLIO_TIME_FORMAT);

    breaks[index] = {breakEnd, breakStart, date}
    setState({...state, breaks: [...breaks]})
  }

  return (
    <StyledSidebar placement="end" backdrop show={show} scroll={false}>
      <SidebarHeader toggleCanvas={toggleSidebar}>
        <SidebarAvatar avatarCss="" name={name} profilePhoto={employee?.profilePhoto}/>
        <StyledEmployeeName>{name}</StyledEmployeeName>
      </SidebarHeader>
      <StyledContentHolder>
        <CardLoader show={loading}/>
        <SidebarDatePicker date={date} handleChange={handleDateChange} disabled/>
        <TimeRangePicker
          endTime={parseEndTime(state.endTime)}
          startTime={parseStartTime(state.startTime)}
          handleEndChanged={handleEndTimeChange}
          handleStartChanged={handleStartTimeChange}
        />
        <RepeatUntil
          setRepeatTo={setRepeatTo}
          repeatTo={state.repeatToDate}
          setRepeatEvery={setRepeatEvery}
          repeatEvery={state.repeatEvery}
        />
        <NotWorkingSwitch notWorking={state.notWorking} toggleWorking={toggleWorking}/>
        <WeekDayPicker
          show={state.repeatEvery !== 0}
          selectedDays={state.selectedDays}
          onWeekDayItemClicked={handleWeekDayItemClicked}
        />
        <StyledDivider>&nbsp;</StyledDivider>
        <SidebarDivider text={intl.formatMessage({id: "work.schedule.break.section"})}/>
        {
          state.breaks.map((pause: IWorkScheduleBreakItem, index: number) =>
            <TimeRangePicker
              key={index}
              index={index}
              hideLabels={true}
              showDeleteIcon={true}
              endTime={parseEndTime(pause.breakEnd)}
              startTime={parseStartTime(pause.breakStart)}
              handleEndChanged={handlePauseEndChanged}
              handleStartChanged={handlePauseStartChanged}
              handleItemRemoveClick={removePauseFromBreaks}
            />
          )
        }
        <PrimaryButton
          onClick={handleAddPause}
          className="w-100 btn-falcon-default mt-4 mb-5"
          title={intl.formatMessage({id: "work.schedule.add.break"})}
        />
      </StyledContentHolder>
      <StyledSidebarFooter>
        <PrimaryButton
          onClick={toggleSidebar}
          className="w-100 btn-falcon-default me-2"
          title={intl.formatMessage({id: "common.form.elements.back"})}
        />
        <PrimaryButton
          className="w-100 ms-2"
          onClick={handleSubmitAsync}
          isBusy={updateOptions.loading}
          disabled={loading || !isValid()}
          title={intl.formatMessage({id: "common.form.element.save"})}
        />
      </StyledSidebarFooter>
    </StyledSidebar>
  )
}

const StyledSidebar = styled(Offcanvas)`
`

const StyledEmployeeName = styled.h1`
  font-weight: 600;
  font-size: 1.44rem;
  color: var(--falcon-gray-600);
`

const StyledContentHolder = styled(Offcanvas.Body)`
  padding-left: 1rem;
  padding-right: 1rem;
`

const StyledDivider = styled.div`
`

const StyledSidebarFooter = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-color: var(--falcon-200);
  border-top: 1px solid var(--falcon-border-color);
`
