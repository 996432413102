import { format } from "date-fns";
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBlockedTimeState, IGetBlockedTimeResponse } from "../Types";
import { CUTLIO_DATE_FORMAT } from "../../Application/Contants";
import { IEmployeeListItem } from "../../Employees/Types";

export const initialState: IBlockedTimeState = {
  reason: "",
  employeeId: 0,
  employeeName: "",
  endTime: "13:00",
  isAllDay: false,
  startTime: "12:00",
  profilePhoto: undefined,
  selectedDate: format(new Date(), CUTLIO_DATE_FORMAT)
}

const _resetBlockedTimeState: CaseReducer<IBlockedTimeState> = (state: IBlockedTimeState) => {
  state.reason = "";
  state.employeeId = 0;
  state.employeeName = "";
  state.startTime = "12:00"
  state.endTime = "13:00";
  state.profilePhoto = undefined;
  format(new Date(), CUTLIO_DATE_FORMAT)
}

const _setBlockedTimeEndTime: CaseReducer<IBlockedTimeState, PayloadAction<string>> = (state: IBlockedTimeState, action: PayloadAction<string>) => {
  state.endTime = action.payload
}

const _toggleBlockedTimeAllDay: CaseReducer<IBlockedTimeState> = (state: IBlockedTimeState) => {
  state.isAllDay = !state.isAllDay
}

const _setBlockedTimeStartTime: CaseReducer<IBlockedTimeState, PayloadAction<string>> = (state: IBlockedTimeState, action: PayloadAction<string>) => {
  state.startTime = action.payload
}

const _setBlockedTimeState: CaseReducer<IBlockedTimeState, PayloadAction<IGetBlockedTimeResponse>> = (state: IBlockedTimeState, action: PayloadAction<IGetBlockedTimeResponse>) => {
  state.endTime = action.payload.blockedTime.response.endTime
  state.selectedDate = action.payload.blockedTime.response.date
  state.startTime = action.payload.blockedTime.response.startTime
  state.reason = action.payload.blockedTime.response.description
  state.employeeId = action.payload.blockedTime.response.employeeId
  state.profilePhoto = action.payload.blockedTime.response.profilePhoto
  state.employeeName = `${action.payload.blockedTime.response.firstName} ${action.payload.blockedTime.response.lastName}`
}

const _setSelectedBlockedDate: CaseReducer<IBlockedTimeState, PayloadAction<string>> = (state: IBlockedTimeState, action: PayloadAction<string>) => {
  state.selectedDate = action.payload
}

const _setBlockedTimeReason: CaseReducer<IBlockedTimeState, PayloadAction<string>> = (state: IBlockedTimeState, action: PayloadAction<string>) => {
  state.reason = action.payload
}

const _setBlockedTimeSelectedEmployee: CaseReducer<IBlockedTimeState, PayloadAction<IEmployeeListItem>> = (state: IBlockedTimeState, action: PayloadAction<IEmployeeListItem>) => {
  state.employeeId = action.payload.id
  state.profilePhoto = action.payload.profilePhoto
  state.employeeName = `${action.payload.firstName} ${action.payload.lastName}`
}

const BlockedTimeSlice = createSlice({
  name: "blockedTime",
  initialState: initialState,
  reducers: {
    setBlockedTimeState: _setBlockedTimeState,
    setBlockedTimeReason: _setBlockedTimeReason,
    resetBlockedTimeState: _resetBlockedTimeState,
    toggleBlockedTimeAllDay: _toggleBlockedTimeAllDay,
    setSelectedBlockedDate: _setSelectedBlockedDate,
    setBlockedTimeEndTime: _setBlockedTimeEndTime,
    setBlockedTimeStartTime: _setBlockedTimeStartTime,
    setBlockedTimeSelectedEmployee: _setBlockedTimeSelectedEmployee,
  }
})


export const BlockedTimeReducer = BlockedTimeSlice.reducer
export const {
  setBlockedTimeSelectedEmployee,
  setBlockedTimeStartTime,
  toggleBlockedTimeAllDay,
  setBlockedTimeEndTime,
  setSelectedBlockedDate,
  resetBlockedTimeState,
  setBlockedTimeState,
  setBlockedTimeReason,
} = BlockedTimeSlice.actions