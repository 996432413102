import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { eachDayOfInterval, format } from "date-fns";
import { useLocalizationContext } from "../../../Cutlio.Providers/LocalizationProvider";

interface Props {
  end: Date;
  start: Date;
}

export const WorkScheduleWeekDisplay = ({start, end}: Props) => {
  const intl = useIntl();
  const {currentLocale} = useLocalizationContext()
  const daysBetween = eachDayOfInterval({start, end})

  function getStyledWeekDay(date: Date, index: number) {
    return <StyledWeekDay key={index}>{format(date, "eee P", {locale: currentLocale}).slice(0, -5)}</StyledWeekDay>;
  }

  return (
    <StyledContentWrapper>
      <StyledContent>{intl.formatMessage({id: "work.schedule.header.employees"})}</StyledContent>
      {daysBetween.map(getStyledWeekDay)}
    </StyledContentWrapper>
  )
}

const StyledContentWrapper = styled(Col)`
  display: flex;
  font-size: 1.1em;
  font-weight: 700;
  flex-direction: row;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: var(--falcon-body-color);
  background-color: var(--falcon-body-bg);
`

const StyledContent = styled(Col)`
  display: flex;
  font-size: 1em;
  align-items: center;
  justify-content: center;
  color: var(--falcon-gray-600);
`

const StyledWeekDay = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: var(--falcon-gray-600);
`
