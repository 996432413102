import React from 'react'
import cn from "classnames";
import Avatar from "react-avatar";
import styled from "styled-components";

interface Props {
  name?: string
  avatarCss?: string
  profilePhoto?: string
}

export const SidebarAvatar = ({name, profilePhoto, avatarCss}: Props) => {
  return (
    <StyledHolder>
      <StyledWrapper className="border border-200">
        <Avatar
          round
          size="6rem"
          name={name}
          maxInitials={2}
          src={profilePhoto}
          className={cn("cursor-pointer", avatarCss)}
        />
      </StyledWrapper>
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
`

const StyledWrapper = styled.div`
  padding: .5rem;
  --falcon-bg-opacity: 1;
  border-radius: var(--falcon-border-radius-pill);
  background-color: rgba(var(--falcon-white-rgb), var(--falcon-bg-opacity));
`
