import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import { PersistGate } from "redux-persist/integration/react";
import { AvatarProvider } from "../Cutlio.Providers/AvatarProvider";
import { ApplicationLayout } from "../Cutlio.Providers/LayoutProvider";
import CutlioGraphQLProvider from "../Cutlio.Providers/GraphQLProvider";
import { CUTLIO_BREAKPOINTS } from "../Cutlio.Config/Theme/BreakPoints";
import { LocalizationProvider } from "../Cutlio.Providers/LocalizationProvider";
import { ApplicationStore, persistor } from "../Cutlio.Providers/ApplicationStore";

export const CutlioApplication = () => {
  return (
    <ThemeProvider breakpoints={CUTLIO_BREAKPOINTS}>
      <AvatarProvider>
        <BrowserRouter>
          <Provider store={ApplicationStore}>
            <PersistGate persistor={persistor}>
              <LocalizationProvider>
                <CutlioGraphQLProvider>
                  <ApplicationLayout/>
                </CutlioGraphQLProvider>
              </LocalizationProvider>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </AvatarProvider>
    </ThemeProvider>
  )
}