export enum EFullCalendarEnum {
  DEFAULT_VIEW = "timeGridWeek",
  RESOURCE_VIEW = "resourceTimeGridDay",
}

export enum EAppointmentStatusEnum {
  CONFIRMED = 1,
  DECLINED = 2,
  CANCELED = 3,
  NEED_APPROVAL = 4,
  NO_SHOW = 5,
  DONE = 6,
  CLIENT_CANCELED = 7,
  FULLY_PAID = 10,
  TIME_RESERVATION = 99,
}