import { gql, useMutation } from "@apollo/client";
import { ICreateBlockedTimeRequest, ICreateBlockedTimeResponse } from "../Types";

export const CREATE_BLOCKED_TIME_MUTATION = gql`
    mutation CreateBlockedTimeMutation($request: CreateBlockedTimeCommandDtoInput!) {
        createBlockedTime(input: { input: {request: $request} }) {
            partnerCreateBlockedTimePayload{
                created
            }
        }
    }
`

/**
 * Use delete mutation
 */
export const useCreateBlockedTimeMutation = () =>
  useMutation<ICreateBlockedTimeResponse, ICreateBlockedTimeRequest>(
    CREATE_BLOCKED_TIME_MUTATION, {
      fetchPolicy: 'network-only'
    });