import React from 'react'
import cn from "classnames";
import styled from "styled-components";
import { EventContentArg } from "@fullcalendar/core";
import { StatusIndicator } from '../StatusIndicator';
import { useEventStyles } from "../../Hooks/EventStyles";
import { EAppointmentStatusEnum } from "../../Enums";
import { useEventDisplayTime } from "../../Hooks/EventTime";
import { useEventClientName } from "../../Hooks/EventClientName";
import { useReservationSidebarContext } from "../../../../Cutlio.Providers/ReservationSidebarProvider/Context";

interface Props {
  eventInfo: EventContentArg
}

export const Event = ({eventInfo}: Props) => {
  const eventStyles = useEventStyles(eventInfo)
  const eventTime = useEventDisplayTime(eventInfo)
  const clientName = useEventClientName(eventInfo)
  const {status} = eventInfo?.event.extendedProps;
  const bulletStyle = {backgroundColor: eventStyles.color};
  const {openAppointmentDetails, openBlockedTimeDetails} = useReservationSidebarContext()
  const shouldHideDivider = status === EAppointmentStatusEnum.TIME_RESERVATION || !status;

  function handleClick() {
    const id = eventInfo.event.groupId;
    return status === EAppointmentStatusEnum.TIME_RESERVATION ? openBlockedTimeDetails(id) : openAppointmentDetails(id);
  }

  return (
    <StyleEventHolder style={eventStyles} onClick={handleClick}>
      <StyledWrapper>
        <StyledTimeHolder>
          <StyledBullet style={bulletStyle}/>
          <StyledTimeText>{eventTime}</StyledTimeText>
        </StyledTimeHolder>
        <StyledClientInfoHolder>
          <StyledTitleText className="fs-10">{clientName}</StyledTitleText>
          <StyledDivider className={cn({'d-none': shouldHideDivider})}>-</StyledDivider>
          <StyledServiceText>{eventInfo?.event.title || ""}</StyledServiceText>
        </StyledClientInfoHolder>
      </StyledWrapper>
      <StatusIndicator status={status}/>
    </StyleEventHolder>
  )
}


const StyleEventHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  border-radius: .4rem;
  border-left: .3rem solid;
`

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-top: .25rem;
  padding-left: .35rem;
  white-space: nowrap;
  flex-direction: column;
  text-overflow: ellipsis;
`

const StyledTimeHolder = styled.div`
  display: flex;
  color: inherit;
  font-size: .85rem;
  font-weight: 700;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
`

const StyledBullet = styled.div`
  width: .4em;
  height: .4em;
  border-radius: 50%;
  margin-right: .4em;
  background-color: inherit;
`

const StyledTimeText = styled.span`
  font-size: .9rem;
`

const StyledDivider = styled.span`
  margin-left: .25rem;
  margin-right: .25rem;
`

const StyledClientInfoHolder = styled.div`
  margin-top: -4px;
  padding-left: .5rem;
`

const StyledTitleText = styled.span`
  color: inherit;
  font-weight: 600;
  font-size: .85rem;
  padding-left: .25rem;
`

const StyledServiceText = styled.span`
  color: inherit;
  font-weight: 600;
  font-size: .85rem;
`