import React from 'react'
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onTextChanged?(text: string): void
}

export const SearchBox = ({onTextChanged}: Props) => {
  const intl = useIntl()
  const placeholder = intl.formatMessage({id: "common.form.element.search.placeholder"})

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!!onTextChanged) onTextChanged(event.target.value)
  }

  return (
    <div className="search-box">
      <Form className="position-relative">
        <Form.Control type="search" placeholder={placeholder} className="rounded-pill search-input" onChange={handleTextChange}/>
        <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon"/>
      </Form>
    </div>
  )
}