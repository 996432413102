import React from "react";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useLocalizationContext } from "../../Cutlio.Providers/LocalizationProvider";

interface Props extends ReactDatePickerProps {
  selectedStartDate: Date;
  selectedEndDate?: Date;
  isRange: boolean;
}

export const CalendarDatePicker = (props: Props) => {
  const {currentLocale} = useLocalizationContext()

  function formatRange(): string {
    const locale = {locale: currentLocale};
    const {selectedStartDate, selectedEndDate} = props;
    return `${format(selectedStartDate, "PP", locale)} - ${format(selectedEndDate!, "PP", locale)}`;
  }

  function formatCalendarDate() {
    const {selectedStartDate} = props;
    const locale = {locale: currentLocale};
    const todayMessage = <FormattedMessage id="appointment.list.view.current.date.today"/>;
    return selectedStartDate === new Date() ? todayMessage : format(selectedStartDate, "ccc PP", locale);
  }

  function getInput() {
    return (
      <Button size="sm" variant="falcon-primary" className="px-lg-4 py-1 text-capitalize">
        {props.isRange ? formatRange() : formatCalendarDate()}
      </Button>
    )
  }

  return (
    <DatePicker
      {...props}
      calendarStartDay={1}
      customInput={getInput()}
      endDate={props.selectedEndDate}
      selected={props.selectedStartDate}
      startDate={props.selectedStartDate}
    />
  )
}