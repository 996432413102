import { format } from "date-fns";
import { useIntl } from "react-intl";
import styled from "styled-components";
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom";
import { Container, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useGetBlockingTimeQuery } from "../Queries";
import { useBlockedTimeStartTime } from "../Hooks";
import { IEmployeeListItem } from "../../Employees/Types";
import { EAppointmentStatusEnum } from "../../Calendar/Enums";
import { BusyIndicator } from "../../../Cutlio.Common/Loader";
import { CardLoader } from "../../../Cutlio.Common/CardLoader";
import { BlockedTimeInput } from "../Components/BlockedTimeInput";
import { SidebarAvatar } from "../../../Cutlio.Common/SidebarAvatar";
import { SidebarHeader } from "../../../Cutlio.Common/SidebarHeader";
import { ButtonIcon } from "../../../Cutlio.Common/Buttons/ButtonIcon";
import { SidebarDivider } from "../../../Cutlio.Common/SidebarDivider";
import { IRootState } from "../../../Cutlio.Providers/ApplicationState";
import { BlockedTimeEmployee } from "../Components/BlockedTimeEmployee";
import { TimeRangePicker } from "../../../Cutlio.Common/TimeRangePicker";
import { SidebarDatePicker } from "../../../Cutlio.Common/SidebarDatePicker";
import { PrimaryButton } from "../../../Cutlio.Common/Buttons/PrimaryButton";
import { BlockedTimeDeleteWarning } from "../Components/BlockedTimeDeleteWarning";
import { SelectEmployeeContainer } from "../../Employees/Container/SelectEmployee";
import { AppointmentStatus } from "../../Appointment/Components/AppointmentStatus";
import { CUTLIO_DATE_FORMAT, CUTLIO_TIME_FORMAT } from "../../Application/Contants";
import { useCreateBlockedTimeMutation } from "../Mutations/CreateBlockedTimeMutation";
import { GET_APPOINTMENTS_QUERY } from "../../Calendar/Queries/GetAppointmentsQuery";
import { useUpdateBlockedTimeMutation } from "../Mutations/UpdateBlockedTimeMutation";
import { useCurrentLocation } from "../../../Cutlio.Providers/LocationsProvider/Context";
import { ICutlioError, useApolloError } from "../../../Cutlio.Common/Hooks/Error/ApolloNetworkError";
import { useReservationSidebarContext } from "../../../Cutlio.Providers/ReservationSidebarProvider/Context";
import { IBlockedTimeRequest, ICreateBlockedTimeRequest, IGetBlockedTimeResponse, IUpdateBlockedTime, IUpdateBlockedTimeRequest } from "../Types";
import { resetBlockedTimeState, setBlockedTimeEndTime, setBlockedTimeSelectedEmployee, setBlockedTimeStartTime, setBlockedTimeState, setSelectedBlockedDate, toggleBlockedTimeAllDay, } from "../Redux";
import { BlockedTimeAllDayToggleButton } from "../Components/BlockedTimeAllDayToggleButton";

export const BlockedTimeContainer = () => {
  const intl = useIntl()
  const params = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const {currentLocation} = useCurrentLocation()
  const {toggleSidebar} = useReservationSidebarContext()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {loading} = useGetBlockingTimeQuery(getId(), {onCompleted});
  const blockedTimeProperties = useSelector((state: IRootState) => state.blockedTime);
  const [createBlockedTime, createBlockedTimeOptions] = useCreateBlockedTimeMutation()
  const [updateBlockedTime, updateBlockedTimeOptions] = useUpdateBlockedTimeMutation()
  const selectedCalendarDate = useSelector((state: IRootState) => state.calendar.formattedStart)
  const createError: ICutlioError = useApolloError(createBlockedTimeOptions.error, createBlockedTimeOptions.loading)
  const blockedTimeEndTime = useBlockedTimeStartTime(blockedTimeProperties.selectedDate, blockedTimeProperties.endTime)
  const blockedTimeStartTime = useBlockedTimeStartTime(blockedTimeProperties.selectedDate, blockedTimeProperties.startTime)

  useEffect(() => {
    return () => {
      dispatch(resetBlockedTimeState)
    }
  }, [location.pathname, dispatch])

  function getId() {
    return !params?.id ? 0 : Number(params.id)
  }

  function isFormValid() {
    return blockedTimeProperties.startTime < blockedTimeProperties.endTime && !!blockedTimeProperties.employeeId
  }

  function onCompleted(response: IGetBlockedTimeResponse) {
    if (!response?.blockedTime?.response) return;
    dispatch(setBlockedTimeState(response))
  }

  function handleChange(date: Date) {
    const formattedDate = format(date, CUTLIO_DATE_FORMAT)
    dispatch(setSelectedBlockedDate(formattedDate))
  }

  function handleServiceEndChanged(date: Date) {
    const time = format(date, CUTLIO_TIME_FORMAT)
    dispatch(setBlockedTimeEndTime(time))
  }

  function handleServiceStartChanged(date: Date) {
    const time = format(date, CUTLIO_TIME_FORMAT)
    dispatch(setBlockedTimeStartTime(time))
  }

  function handleEmployeeChange(employee: IEmployeeListItem | null) {
    if (employee) dispatch(setBlockedTimeSelectedEmployee(employee))
  }

  function getRequest(): IBlockedTimeRequest {
    const {startTime, endTime, selectedDate, employeeId, reason, isAllDay} = blockedTimeProperties
    return {
      isAllDay: isAllDay,
      endTime: endTime,
      description: reason,
      startTime: startTime,
      employeeId: employeeId,
      startDate: selectedDate,
      isCollectiveVacation: false,
      locationId: currentLocation.locationId,
    }
  }

  function getUpdateRequest(): IUpdateBlockedTime {
    const {startTime, endTime, selectedDate, employeeId, reason} = blockedTimeProperties
    return {
      isAllDay: false,
      endTime: endTime,
      endDate: undefined,
      description: reason,
      startTime: startTime,
      appointmentId: getId(),
      employeeId: employeeId,
      startDate: selectedDate,
      isCollectiveVacation: false,
      locationId: currentLocation.locationId
    }
  }

  function handleSubmit() {
    const isEdit = getId() !== 0;
    const variables: ICreateBlockedTimeRequest = {request: getRequest()};
    const updateVariabes: IUpdateBlockedTimeRequest = {request: getUpdateRequest()};

    const refetchVariables = {locationId: currentLocation.locationId, selectedDate: selectedCalendarDate};
    const refetchQueries = [{query: GET_APPOINTMENTS_QUERY, variables: refetchVariables}]

    if (isEdit) updateBlockedTime({variables: updateVariabes, awaitRefetchQueries: true, refetchQueries}).then(handleSuccessCreate).catch(handleApolloError)
    else createBlockedTime({variables, awaitRefetchQueries: true, refetchQueries}).then(handleSuccessCreate).catch(handleApolloError)
  }

  function handleSuccessCreate() {
    toggleSidebar()
  }

  function handleApolloError() {
  }

  function toggleDeleteModal() {
    setShowDeleteModal(!showDeleteModal)
  }

  function toggleAllDay() {
    dispatch(toggleBlockedTimeAllDay())
  }

  return (
    <React.Fragment>
      <SidebarHeader toggleCanvas={toggleSidebar}>
        <SidebarAvatar name={blockedTimeProperties.employeeName} profilePhoto={blockedTimeProperties.profilePhoto}/>
        <AppointmentStatus status={EAppointmentStatusEnum.TIME_RESERVATION}/>
        <BlockedTimeEmployee employeeName={blockedTimeProperties.employeeName}/>
      </SidebarHeader>
      <StyledContentHolder>
        <CardLoader show={loading}/>
        <SidebarDatePicker date={blockedTimeProperties.selectedDate} handleChange={handleChange}/>
        <StyledContainer>
          <StyledServiceLabel>
            {intl.formatMessage({id: "blocked.time.service.number.placeholder"})}
          </StyledServiceLabel>
          <SelectEmployeeContainer
            employeeId={blockedTimeProperties.employeeId}
            onEmployeeItemSelected={handleEmployeeChange}
          />
          <TimeRangePicker
            hideLabels={true}
            endTime={blockedTimeEndTime}
            startTime={blockedTimeStartTime}
            disabled={blockedTimeProperties.isAllDay}
            handleEndChanged={handleServiceEndChanged}
            handleStartChanged={handleServiceStartChanged}
          />
          <BlockedTimeAllDayToggleButton
            toggleCheckbox={toggleAllDay}
            checked={blockedTimeProperties.isAllDay}
          />
        </StyledContainer>
        <SidebarDivider text={intl.formatMessage({id: "blocked.time.pause.reason"})}/>
        <BlockedTimeInput value={blockedTimeProperties.reason}/>
      </StyledContentHolder>
      <StyledSidebarFooter>
        <StyledActionsHolder hidden={getId() === 0}>
          <ButtonIcon icon="trash" className="w-100 me-1" variant="outline-danger" onClick={toggleDeleteModal}>
            <StyledText>{intl.formatMessage({id: "blocked.time.delete.button.text"})}</StyledText>
          </ButtonIcon>
        </StyledActionsHolder>
        <StyledRow>
          <PrimaryButton
            onClick={toggleSidebar}
            className="w-100 btn-falcon-default me-2"
            title={intl.formatMessage({id: "common.form.elements.back"})}
          />
          <PrimaryButton
            className="w-100 ms-2"
            onClick={handleSubmit}
            disabled={!isFormValid()}
            title={intl.formatMessage({id: "common.form.element.save"})}
            isBusy={createBlockedTimeOptions.loading || updateBlockedTimeOptions.loading}
          />
        </StyledRow>
      </StyledSidebarFooter>
      <BlockedTimeDeleteWarning show={showDeleteModal} onBackClicked={toggleDeleteModal}/>
      <BusyIndicator show={loading || createBlockedTimeOptions.loading || updateBlockedTimeOptions.loading}/>
    </React.Fragment>
  )
}

const StyledContentHolder = styled(Offcanvas.Body)`
  padding-left: 1rem;
  padding-right: 1rem;
`
const StyledContainer = styled(Container)`
  padding: 2rem 0;
`

const StyledServiceLabel = styled.h6`
  opacity: .6;
  font-weight: 600;
  font-size: .95rem;
  font-style: italic;
  padding-bottom: .5rem;
  color: var(--falcon-gray-600);
`

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledActionsHolder = styled(Container)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: .1rem;
  padding-right: .1rem;
  padding-bottom: 1rem;
  justify-content: center;
`

const StyledText = styled.span`
  padding-left: .5rem;
`


const StyledSidebarFooter = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-color: var(--falcon-200);
  border-top: 1px solid var(--falcon-border-color);
`