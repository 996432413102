import React from 'react'
import styled from "styled-components";
import { EAppointmentStatusEnum } from "../../../Calendar/Enums";
import { useAppointmentDisplayStatus } from "../../Hooks/AppointmentStatus";

interface Props {
  status: EAppointmentStatusEnum
}

export const AppointmentStatus = ({status}: Props) => {
  const {label, backgroundColor} = useAppointmentDisplayStatus(status)

  return (
    <StyledHolder style={{backgroundColor}}>
      <StyledLabel className="fs-10">{label}</StyledLabel>
    </StyledHolder>
  )
}


const StyledHolder = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
  align-items: center;
  padding-bottom: .1rem;
  justify-content: center;
  color: var(--falcon-white);
`

const StyledLabel = styled.span`
  color: white;
  font-weight: 700;
`