import React from 'react'
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EAppointmentStatusEnum } from "../../Enums";

interface Props {
  status: EAppointmentStatusEnum
}

export const StatusIndicator = ({status}: Props) => {
  return (
    <StyledStatusHolder>
      {status === EAppointmentStatusEnum.DONE && <StyledPaidIcon icon="file-circle-check"/>}
      {status === EAppointmentStatusEnum.NO_SHOW && <StyledMissedIcon icon="file-circle-minus"/>}
      {status === EAppointmentStatusEnum.FULLY_PAID && <StyledPaidIcon icon="file-circle-check"/>}
    </StyledStatusHolder>
  )
}

const StyledStatusHolder = styled.div`
  right: 5px;
  bottom: 5px;
  position: absolute;
`

const StyledPaidIcon = styled(FontAwesomeIcon)`
  color: var(--falcon-gray-600);
`

const StyledMissedIcon = styled(FontAwesomeIcon)`
  color: var(--falcon-danger);
`