import styled from "styled-components";
import React, { useState } from 'react'
import { Card } from "react-bootstrap";
import { endOfWeek, startOfWeek } from "date-fns";
import { IWorkScheduleListItem } from "../Types";
import { IEmployeeListItem } from "../../Employees/Types";
import { useEmployeesQuery } from "../../Employees/Query";
import { useLocationWorkScheduleQuery } from "../Queries";
import { BusyIndicator } from "../../../Cutlio.Common/Loader";
import { WorkScheduleHeader } from "../Components/WorkScheduleHeader";
import { WorkScheduleLineItem } from "../Components/WorkScheduleLineItem";
import { WorkScheduleWeekDisplay } from "../Components/WorkScheduleWeekDisplay";
import { useCurrentLocation } from "../../../Cutlio.Providers/LocationsProvider/Context";
import { WorkScheduleUpsertContainer } from "../../WorkScheduleUpsert/Container/WorkScheduleUpsertContainer";

interface State {
  endDate: Date
  startDate: Date
  sidebarOpen: boolean
  selectedDay?: string | null;
  selectedEmployee?: IEmployeeListItem | null
  selectedSchedule?: IWorkScheduleListItem | null
}

const DEFAULT_STATE: State = {
  selectedDay: null,
  sidebarOpen: false,
  selectedSchedule: null,
  selectedEmployee: null,
  endDate: endOfWeek(new Date(), {weekStartsOn: 1}),
  startDate: startOfWeek(new Date(), {weekStartsOn: 1}),
}

export const WorkScheduleListContainer = () => {
  const {currentLocation} = useCurrentLocation()
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const employeesQuery = useEmployeesQuery(currentLocation.locationId)
  const employees: Array<IEmployeeListItem> = employeesQuery.data?.employees.response || []
  const workScheduleQuery = useLocationWorkScheduleQuery(currentLocation.locationId, state.startDate, state.endDate, false);
  const locationWorkSchedule: Array<IWorkScheduleListItem> = workScheduleQuery?.data?.locationWorkSchedule?.response || []

  function handleDateChanged(date: Date) {
    const end = endOfWeek(date, {weekStartsOn: 1})
    const start = startOfWeek(date, {weekStartsOn: 1})
    setState({...state, startDate: start, endDate: end})
  }

  function toggleSidebar() {
    setState({...state, sidebarOpen: !state.sidebarOpen})
  }

  function handleSchedulePress(schedule: IWorkScheduleListItem) {
    const employee = employees.find(entity => entity.id === schedule.employeeId)
    setState({...state, selectedSchedule: schedule, sidebarOpen: true, selectedEmployee: employee})
  }

  function renderEmployeeWorkSchedule(employee: IEmployeeListItem, index: number) {
    const schedule = locationWorkSchedule.filter(entities => entities.employeeId === employee.id)
    return <WorkScheduleLineItem schedule={schedule} key={index} employee={employee} onSchedulePress={handleSchedulePress}/>
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <WorkScheduleHeader
          endDate={state.endDate}
          startDate={state.startDate}
          onDateChanged={handleDateChanged}
        />
      </StyledHeader>
      <StyledContent>
        <WorkScheduleWeekDisplay end={state.endDate} start={state.startDate}/>
        {employees.map(renderEmployeeWorkSchedule)}
      </StyledContent>
      <BusyIndicator show={employeesQuery.loading || workScheduleQuery.loading}/>
      <WorkScheduleUpsertContainer
        show={state.sidebarOpen}
        toggleSidebar={toggleSidebar}
        employee={state.selectedEmployee}
        schedule={state.selectedSchedule}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Card)``

const StyledHeader = styled(Card.Header)``

const StyledContent = styled(Card.Body)`
  padding-top: 0;
  padding-left: 0;
  font-size: 0.8333333333rem !important;;
`
