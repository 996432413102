import storage from 'redux-persist/lib/storage'
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist/es/constants";
import { RootReducer } from "../ApplicationState";
import { APPOINTMENT_SELECTION, APPOINTMENT_SERVICE_END_TIME_PATH, APPOINTMENT_SERVICE_START_TIME_PATH, CALENDAR_SET_DATE, CALENDAR_SET_END_PATH, CALENDAR_SET_START_PATH } from "./Constants";

const persistConfig = {
  version: 1,
  key: "@app-state",
  storage: storage,
  whitelist: ['application', 'calendarResource']
}

// AppConfiguration
const persistedReducer = persistReducer(persistConfig, RootReducer);
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, APPOINTMENT_SELECTION, CALENDAR_SET_DATE, APPOINTMENT_SERVICE_END_TIME_PATH, APPOINTMENT_SERVICE_START_TIME_PATH];
const ignoredPaths = [CALENDAR_SET_END_PATH, CALENDAR_SET_START_PATH]

const ApplicationStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware({serializableCheck: {ignoredActions, ignoredPaths}})
})


const persistor = persistStore(ApplicationStore);
export { ApplicationStore, persistor };