import {gql, QueryHookOptions, useQuery} from "@apollo/client";
import {ICalendarResourceRequest, ICalendarResourcesResponse} from "../Types";

export const GET_CALENDAR_RESOURCES = gql`
    query GetCalendarResources($locationId: Int!, $selectedDate: Date!) {
        webCalendarResources(locationId: $locationId, selectedDate: $selectedDate) {
            response {
                id
                title
                isWorking
                profilePhoto
                localizedEndTime
                localizedStartTime
                locationCalendarEndHours
                locationCalendarStartHours
                defaultCalendarLineMinutes
                defaultCalendarSplitMinutes
                businessHours {
                    endTime
                    startTime
                    resourceId
                    daysOfWeek
                    localizedStartTime
                    localizedEndTime
                }
            }
        }
    }
`

/**
 * Use calendar resources query
 */
export const useCalendarResourcesRequest = (locationId: number, selectedDate: string, options?: QueryHookOptions<ICalendarResourcesResponse, ICalendarResourceRequest>) =>
  useQuery<ICalendarResourcesResponse, ICalendarResourceRequest>(GET_CALENDAR_RESOURCES, {
      variables: {selectedDate, locationId},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      ...options,
    }
  );