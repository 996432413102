import { useIntl } from "react-intl";


export const useRepeatOptions = () => {
  const intl = useIntl()

  return [
    {value: 0, label: intl.formatMessage({id: "work.schedule.repeat.option.no.repeat"})},
    {value: 1, label: intl.formatMessage({id: "work.schedule.repeat.option.every.week"})},
    {value: 2, label: intl.formatMessage({id: "work.schedule.repeat.option.every.two.weeks"})},
    {value: 3, label: intl.formatMessage({id: "work.schedule.repeat.option.every.three.weeks"})}
  ]
}