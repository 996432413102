import { maxBy, minBy } from "lodash";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef } from 'react'
import FullCalendar from "@fullcalendar/react";
import { useDispatch, useSelector } from "react-redux";
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import scrollGrid from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGrid from '@fullcalendar/resource-timegrid';
import { ResourceInput, ResourceLabelContentArg } from "@fullcalendar/resource";
import { DatesSetArg, EventContentArg, EventSourceInput } from "@fullcalendar/core";
import { setSelectedDate } from "../Redux/CalendarState";
import { EFullCalendarEnum } from "../Enums";
import { Event } from "../Components/Event";
import { ICalendarResourcesResponse } from "../Types";
import { AllDayEvent } from "../Components/AllDayEvent";
import { CalendarHeader } from "../Components/CalendarHeader";
import { ResourceHeader } from "../Components/ResourceHeader";
import { BusyIndicator } from "../../../Cutlio.Common/Loader";
import { CalendarToolbar } from "../Components/CalendarToolbar";
import { setCalendarResources } from "../Redux/CalendarResources";
import { useAppointmentsQuery } from "../Queries/GetAppointmentsQuery";
import { IRootState } from "../../../Cutlio.Providers/ApplicationState";
import { useCalendarResourcesRequest } from "../Queries/CalendarResourcesQuery";
import { ReservationSidebar } from "../../Appointment/Components/ReservationSidebar";
import { useLocalizationContext } from "../../../Cutlio.Providers/LocalizationProvider";
import { useCurrentLocation } from "../../../Cutlio.Providers/LocationsProvider/Context";
import { useReservationSidebarContext } from "../../../Cutlio.Providers/ReservationSidebarProvider/Context";

export const CalendarContainer = () => {
  const intl = useIntl()
  const location = useLocation()
  const dispatch = useDispatch()
  const calendarApi = useRef<FullCalendar>(null)
  const {currentLocation} = useCurrentLocation()
  const {currentLanguage} = useLocalizationContext()
  const {onSelectionCompleted} = useReservationSidebarContext()
  const {resources, view} = useSelector((state: IRootState) => state.calendarResource);
  const {formattedStart, end, start} = useSelector((state: IRootState) => state.calendar);
  const appointmentsQuery = useAppointmentsQuery(currentLocation.locationId, formattedStart);
  const resourceQuery = useCalendarResourcesRequest(currentLocation.locationId, formattedStart, {onCompleted})

  useEffect(() => {
    if (location.pathname.includes('client')) return;
    if (location.pathname.includes('appointment')) return;
    calendarApi?.current?.getApi().unselect()
  }, [location.pathname])

  function handleNextClick() {
    calendarApi?.current?.getApi().next();
  }

  function handlePreviousClick() {
    calendarApi?.current?.getApi().prev();
  }

  function handleDateChanged(date: Date) {
    calendarApi?.current?.getApi().gotoDate(date);
    dispatch(setSelectedDate(date))
  }

  function handleSetSelectedDates(dates: DatesSetArg) {
    dispatch(setSelectedDate(dates.start))
  }

  function getCalendarMinimumTime(): string {
    const resources = resourceQuery?.data?.webCalendarResources?.response || []
    return minBy(resources, resource => resource.locationCalendarStartHours)?.locationCalendarStartHours || "08:00";
  }

  function getCalendarMaximumTime(): string {
    const resources = resourceQuery?.data?.webCalendarResources?.response || []
    return maxBy(resources, resource => resource.locationCalendarEndHours)?.locationCalendarEndHours || "22:00";
  }

  function getIntervalMinutes(): number {
    return resourceQuery?.data?.webCalendarResources?.response[0]?.defaultCalendarLineMinutes || 30
  }

  function onCompleted(result: ICalendarResourcesResponse) {
    const resources = result?.webCalendarResources?.response || []
    const newView = resources?.length > 1 ? EFullCalendarEnum.RESOURCE_VIEW : EFullCalendarEnum.DEFAULT_VIEW;
    calendarApi?.current?.getApi()?.changeView(newView)
    dispatch(setCalendarResources(resources))
  }

  function renderEvent(eventInfo: EventContentArg) {
    if (eventInfo?.event?.allDay) return <AllDayEvent eventInfo={eventInfo}/>
    return <Event eventInfo={eventInfo}/>
  }

  function renderResourceHeader(resource: ResourceLabelContentArg) {
    return <ResourceHeader item={resource}/>
  }

  return (
    <StyledCard>
      <CalendarHeader>
        <CalendarToolbar
          isRange={false}
          selectedDate={start}
          selectedEndDate={end}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          onSelectedDateChanged={handleDateChanged}
        />
      </CalendarHeader>
      <StyledBody>
        <FullCalendar
          firstDay={1}
          height="auto"
          ref={calendarApi}
          editable={false}
          selectable={true}
          allDaySlot={true}
          slotMinWidth={10}
          initialView={view}
          nowIndicator={true}
          selectMirror={true}
          businessHours={true}
          unselectAuto={false}
          duration={{hours: 1}}
          headerToolbar={false}
          locale={currentLanguage}
          slotEventOverlap={false}
          eventContent={renderEvent}
          resourceOrder="orderingNumber"
          select={onSelectionCompleted}
          slotLabelInterval={{hours: 1}}
          datesSet={handleSetSelectedDates}
          resources={resources as ResourceInput}
          slotMaxTime={getCalendarMaximumTime()}
          slotMinTime={getCalendarMinimumTime()}
          resourceLabelContent={renderResourceHeader}
          slotDuration={{minutes: getIntervalMinutes()}}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          slotLabelFormat={{hour: 'numeric', minute: '2-digit', hour12: false}}
          eventTimeFormat={{hour: "2-digit", minute: "2-digit", hourCycle: "h24"}}
          allDayText={intl.formatMessage({id: "appointment.list.view.event.all.day"})}
          events={(appointmentsQuery?.data?.partnerWebAppointments?.response || []) as EventSourceInput}
          plugins={[dayGridPlugin, listPlugin, scrollGrid, timeGridPlugin, interactionPlugin, resourceTimeGrid]}
        />
      </StyledBody>
      <ReservationSidebar/>
      <BusyIndicator show={resourceQuery.loading || appointmentsQuery.loading}/>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  height: auto;
`

const StyledBody = styled(Card.Body)`
  padding: 0;
`