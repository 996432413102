import { debounce } from "lodash";
import { useIntl } from "react-intl";
import React, { useState } from 'react'
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { VirtuosoGrid } from "react-virtuoso";
import { setSelectedClient } from "../../../Appointment/Redux";
import { SearchBox } from "../../../../Cutlio.Common/SearchBox";
import { ClientListItem } from "../../Components/ClientListItem";
import { useClientListQuery } from "../../Queries/GetClientsQuery";
import { SidebarHeader } from "../../../../Cutlio.Common/SidebarHeader";
import { IClientListItem, IClientsRequest, IClientsResponse } from "../../Types";
import { useCurrentLocation } from "../../../../Cutlio.Providers/LocationsProvider/Context";

export const SelectClientContainer = React.memo(() => {
  const pageSize = 20;
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {currentLocation} = useCurrentLocation();
  const {locationId, companyId} = currentLocation;
  const applyLiveSearch = debounce(delayedSearchValue, 300)
  const [searchValue, setSearchValue] = useState<string | null>("");
  const {loading, data, fetchMore} = useClientListQuery(companyId, locationId, 1, pageSize, searchValue);

  function goBack() {
    navigate(-1)
  }

  function delayedSearchValue(text: string) {
    setSearchValue(text)
  }

  function onClientItemClick(item: IClientListItem) {
    dispatch(setSelectedClient(item))
    goBack()
  }

  async function loadMoreAsync() {
    if (loading) return;
    if (!data?.companyClients?.response?.hasNextPage) return;

    const currentPage = data?.companyClients?.response?.currentPage || 1;
    const params: IClientsRequest = {page: currentPage + 1, pageSize: 20, companyId, locationId, searchValue};
    await fetchMore({variables: params, updateQuery: (updateLocalQueryData)});
  }

  function updateLocalQueryData(previousQueryResult: IClientsResponse, {fetchMoreResult}: { fetchMoreResult: IClientsResponse }) {
    return {
      companyClients: {
        id: fetchMoreResult.companyClients.id,
        response: {
          totalItems: fetchMoreResult.companyClients.response.totalItems,
          totalPages: fetchMoreResult.companyClients.response.totalPages,
          hasNextPage: fetchMoreResult.companyClients.response.hasNextPage,
          maxPageSize: fetchMoreResult.companyClients.response.maxPageSize,
          currentPage: fetchMoreResult.companyClients.response.currentPage,
          hasPreviousPage: fetchMoreResult.companyClients.response.hasPreviousPage,
          data: [...previousQueryResult.companyClients.response.data, ...fetchMoreResult.companyClients.response.data]
        }
      }
    }
  }

  return (
    <React.Fragment>
      <SidebarHeader toggleCanvas={goBack} isBackMode>
        <StyledSidebarTitle>{intl.formatMessage({id: "appointment.details.select.client.title"})}</StyledSidebarTitle>
        <SearchBox onTextChanged={applyLiveSearch}/>
      </SidebarHeader>
      <StyledInfiniteList
        overscan={200}
        endReached={loadMoreAsync}
        components={{List: StyledList}}
        totalCount={data?.companyClients?.response?.data?.length || 0}
        itemContent={(index) => <ClientListItem client={data?.companyClients.response.data[index]!} onClick={onClientItemClick}/>}
      />
    </React.Fragment>
  )
})

const StyledList = styled(Offcanvas.Body)`
  margin-top: 1.5rem;;
`

const StyledInfiniteList = styled(VirtuosoGrid)(() => ({
  height: "100%"
}))

const StyledSidebarTitle = styled.h6`
  font-weight: 600;
  font-size: 1.25rem;
  padding-top: 2rem;
  color: var(--falcon-gray-500);
`