import { ICalendarState } from "../Types";
import { format } from "date-fns";
import { CUTLIO_DATE_FORMAT } from "../../Application/Contants";
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ICalendarState = {
  end: new Date(),
  start: new Date(),
  formattedEnd: format(new Date(), CUTLIO_DATE_FORMAT),
  formattedStart: format(new Date(), CUTLIO_DATE_FORMAT)
}

const _setSelectedDate: CaseReducer<ICalendarState, PayloadAction<Date>> = (state: ICalendarState, action: PayloadAction<Date>) => {
  state.end = action.payload
  state.start = action.payload
  state.formattedEnd = format(action.payload, CUTLIO_DATE_FORMAT)
  state.formattedStart = format(action.payload, CUTLIO_DATE_FORMAT)
}

const CalendarSlice = createSlice({
  name: 'calendar',
  initialState: initialState,
  reducers: {
    setSelectedDate: _setSelectedDate,
  }
})

export const CalendarReducer = CalendarSlice.reducer;
export const {setSelectedDate} = CalendarSlice.actions