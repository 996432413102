import React from 'react'
import cn from "classnames";
import { FormikProps } from "formik";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Card, Row } from "react-bootstrap";
import { ISignRequest } from "../Types";
import { Logo } from "../../../Cutlio.Common/Logo";
import { InputField } from "../../../Cutlio.Common/Fields/InputField";
import { FormSummary } from "../../../Cutlio.Common/Summary/FormSummary";
import { PrimaryButton } from "../../../Cutlio.Common/Buttons/PrimaryButton";
import { AuthenticationControls } from "../Components/AuthenticationControls";
import { useFormSummaryError } from "../../../Cutlio.Common/Hooks/Error/FormSummaryError";

interface Props extends FormikProps<ISignRequest> {
}

export const LoginView = ({isSubmitting, submitForm}: Props) => {
  const intl = useIntl()
  const summaryError = useFormSummaryError()
  const appliedPadding = 'px-xxl-7 px-xl-5'

  return (
    <Row>
      <StyledLogoHolder className="pb-4"><Logo height={45}/></StyledLogoHolder>
      <Card>
        <StyledHeaderHolder className="pt-4">
          <StyledHeader className="px-xxl-6">{intl.formatMessage({id: "sign.in.form.title"})}</StyledHeader>
          <FormSummary message={summaryError}/>
        </StyledHeaderHolder>
        <Card.Body className={appliedPadding}>
          <InputField
            name="email"
            icon="envelope"
            id="login-email"
            className="bg-100"
            label={intl.formatMessage({id: 'common.form.element.email'})}
            placeholder={intl.formatMessage({id: "common.form.element.email.placeholder"})}
          />
          <InputField
            name="password"
            type="password"
            className="bg-100"
            id="login-password"
            label={intl.formatMessage({id: 'common.form.element.password'})}
            placeholder={intl.formatMessage({id: "common.form.element.password.placeholder"})}
          />
        </Card.Body>
        <AuthenticationControls className={appliedPadding}/>
        <Card.Footer className={cn(appliedPadding, 'pb-5 pt-4')}>
          <PrimaryButton isBusy={isSubmitting} className="w-100" title={intl.formatMessage({id: 'sign.in.form.sign.in.button.text'})} onClick={submitForm}/>
        </Card.Footer>
      </Card>
    </Row>
  )
}

const StyledLogoHolder = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
`

const StyledHeaderHolder = styled(Card.Header)`
  padding-bottom: 0;
  margin-bottom: -.8em;
`

const StyledHeader = styled.h4`
  font-size: 1.8em;
  text-align: center;
  color: var(--falcon-gray-600);
`
