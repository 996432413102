import React, { useState } from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { Container, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { WalkInButton } from "../Components/WalkInButton";
import { CardLoader } from "../../../Cutlio.Common/CardLoader";
import { EAppointmentStatusEnum } from "../../Calendar/Enums";
import { SidebarHeader } from "../../../Cutlio.Common/SidebarHeader";
import { SidebarAvatar } from "../../../Cutlio.Common/SidebarAvatar";
import { NoReservationInput } from "../Components/NoReservationInput";
import { AppointmentStatus } from "../Components/AppointmentStatus";
import { SelectClientButton } from "../Components/SelectClientButton";
import { IRootState } from "../../../Cutlio.Providers/ApplicationState";
import { AppointmentService } from "../Components/AppointmentService";
import { SidebarDivider } from "../../../Cutlio.Common/SidebarDivider";
import { ButtonIcon } from "../../../Cutlio.Common/Buttons/ButtonIcon";
import { AppointmentClientName } from "../Components/AppointmentClientName";
import { SidebarDatePicker } from "../../../Cutlio.Common/SidebarDatePicker";
import { PrimaryButton } from "../../../Cutlio.Common/Buttons/PrimaryButton";
import { useAppointmentDetailsQuery } from "../Queries/GetAppointmentQuery";
import { AppointmentPublicNote } from "../Components/AppointmentPublicNote";
import { AppointmentMissedWarning } from "../Components/AppointmentMissedWarning";
import { AppointmentCancelWarning } from "../Components/AppointmentCancelWarning";
import { GET_APPOINTMENTS_QUERY } from "../../Calendar/Queries/GetAppointmentsQuery";
import { useCreateAppointmentMutation } from "../Mutations/CreateAppointmentMutation";
import { useUpdateAppointmentMutation } from "../Mutations/UpdateAppointmentMutation";
import { ICutlioError, useApolloError } from "../../../Cutlio.Common/Hooks/Error/ApolloNetworkError";
import { setAppointmentDate, setAppointmentDetails, setIsWalkInFalse, setIsWalkInTrue } from "../Redux";
import { useReservationSidebarContext } from "../../../Cutlio.Providers/ReservationSidebarProvider/Context";
import { IGetAppointmentResponse, IPartnerCreateAppointmentRequest, IPartnerUpdateAppointmentRequest } from "../Types";

export const AppointmentContainer = () => {
  const intl = useIntl()
  const params = useParams()
  const dispatch = useDispatch()
  const {toggleSidebar} = useReservationSidebarContext()
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showMissedModal, setShowMissedModal] = useState(false)
  const [createAppointment, createOptions] = useCreateAppointmentMutation();
  const [updateAppointment, updateOptions] = useUpdateAppointmentMutation();
  const {request, preventFetch} = useSelector((state: IRootState) => state.appointment)
  const createError: ICutlioError = useApolloError(createOptions.error, createOptions.loading)
  const {loading, error} = useAppointmentDetailsQuery(getAppointmentId(), {onCompleted, skip: shouldSkip()})
  const readOnlyActions = [EAppointmentStatusEnum.NO_SHOW, EAppointmentStatusEnum.FULLY_PAID, EAppointmentStatusEnum.DONE]

  function handleSubmitAsync() {
    const refetchVariables = {locationId: request.locationId, selectedDate: request.date};
    const refetchQueries = [{query: GET_APPOINTMENTS_QUERY, variables: refetchVariables}]

    const createVariables: IPartnerCreateAppointmentRequest = {createRequest: {...request}};
    const updateVariables: IPartnerUpdateAppointmentRequest = {updateRequest: {...request}}

    if (!params.id) createAppointment({variables: createVariables, awaitRefetchQueries: true, refetchQueries}).then(handleSuccessCreate).catch(handleApolloError)
    else updateAppointment({variables: updateVariables, awaitRefetchQueries: true, refetchQueries}).then(handleSuccessUpdate).catch(handleApolloError)
  }

  function onCompleted(response: IGetAppointmentResponse) {
    dispatch(setAppointmentDetails(response))
  }

  function shouldSkip() {
    return preventFetch || getAppointmentId() === 0
  }

  function handleSuccessCreate() {
    toggleSidebar()
  }

  function handleSuccessUpdate() {
    toggleSidebar()
  }

  function getAppointmentId(): number {
    return !params?.id ? 0 : Number(params.id)
  }

  function handleApolloError(_: ApolloError | undefined) {
  }

  function toggleWalkIn() {
    return !request.walkIn ? dispatch(setIsWalkInTrue()) : dispatch(setIsWalkInFalse())
  }

  function toggleCancelModal() {
    setShowCancelModal(!showCancelModal)
  }

  function toggleMissedModal() {
    setShowMissedModal(!showMissedModal)
  }

  function handleAppointmentChange(date: Date) {
    dispatch(setAppointmentDate(date))
  }


  return (
    <React.Fragment>
      <SidebarHeader toggleCanvas={toggleSidebar}>
        <SidebarAvatar name={request.clientName} profilePhoto={request.profilePhoto}/>
        <AppointmentStatus status={request.status}/>
        <SelectClientButton show={!request.clientId && !request.walkIn}/>
        <AppointmentClientName show={!!request.clientId && !request.walkIn}/>
        <NoReservationInput show={request.walkIn} value={request?.clientName || ""}/>
        <WalkInButton isWalkIn={request.walkIn} toggleWalkIn={toggleWalkIn}/>
      </SidebarHeader>
      <StyledContentHolder>
        <CardLoader show={loading}/>
        <SidebarDatePicker date={request.date} handleChange={handleAppointmentChange}/>
        <StyledDivider>&nbsp;</StyledDivider>
        {request.services.map((_, index) => <AppointmentService key={index} index={index} showLabel={true}/>)}
        <SidebarDivider text={intl.formatMessage({id: "appointment.details.appointment.notes"})}/>
        <AppointmentPublicNote publicNote={request.note}/>
      </StyledContentHolder>
      <StyledSidebarFooter>
        <StyledActionsHolder hidden={!params.id || readOnlyActions.includes(request.status) || loading}>
          <ButtonIcon icon="ban" className="w-100 me-1" variant="outline-warning" onClick={toggleCancelModal}>
            <StyledText>{intl.formatMessage({id: "appointment.details.actions.cancel"})}</StyledText>
          </ButtonIcon>
          <ButtonIcon icon="user-minus" className="w-100 ms-1" variant="outline-danger" onClick={toggleMissedModal}>
            <StyledText>{intl.formatMessage({id: "appointment.details.actions.no.show"})}</StyledText>
          </ButtonIcon>
        </StyledActionsHolder>
        <PrimaryButton
          className="w-100"
          onClick={handleSubmitAsync}
          isBusy={createOptions.loading || updateOptions.loading}
          title={intl.formatMessage({id: "common.form.element.save"})}
          hidden={readOnlyActions.includes(request.status) || loading}
        />
        <PrimaryButton
          onClick={toggleSidebar}
          className="w-100 btn-falcon-default"
          hidden={!readOnlyActions.includes(request.status) || loading}
          title={intl.formatMessage({id: "common.form.elements.back"})}
        />
      </StyledSidebarFooter>
      <AppointmentCancelWarning show={showCancelModal} onBackClicked={toggleCancelModal}/>
      <AppointmentMissedWarning show={showMissedModal} onBackClicked={toggleMissedModal}/>
    </React.Fragment>
  )
}

const StyledContentHolder = styled(Offcanvas.Body)`
  padding-left: 1rem;
  padding-right: 1rem;
`

const StyledSidebarFooter = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-color: var(--falcon-200);
  border-top: 1px solid var(--falcon-border-color);
`

const StyledActionsHolder = styled(Container)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: .1rem;
  padding-right: .1rem;
  padding-bottom: 1rem;
  justify-content: center;
`

const StyledDivider = styled.div`
`

const StyledText = styled.span`
  padding-left: .5rem;
`

