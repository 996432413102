import { gql, useMutation } from "@apollo/client";

export interface ISignInRequest {
  email: string;
  password: string;
}

export interface ISignInResponse {
  login: {
    partnerAuthenticationPayload: {
      response: {
        email: string
        lastName: string;
        firstName: string;
        accessToken: string;
        refreshToken: string;
        profilePhoto: string | null | undefined;
      }
    }
  }
}

export const SIGN_IN_MUTATION = gql`
    mutation SignInMutation($email: String!, $password: String!){
        login(input: { input: { email: $email, password: $password }}) {
            partnerAuthenticationPayload {
                response {
                    email
                    firstName
                    lastName
                    profilePhoto
                    accessToken
                    refreshToken
                }
            }
        }
    }
`

export const useSignInMutation = () => useMutation<ISignInResponse, ISignInRequest>(SIGN_IN_MUTATION);