import { addMinutes, format, parse } from "date-fns";
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IClientListItem } from "../../Client/Types";
import { IServiceListItem } from "../../Service/Types";
import { EAppointmentStatusEnum } from "../../Calendar/Enums";
import { IExtendedSelectionInfo } from "../../../Cutlio.Providers/ReservationSidebarProvider/Types";
import { IAppointmentDetailsState, IAppointmentServiceTimeChange, IGetAppointmentResponse } from "../Types";
import { CUTLIO_DATE_FORMAT, CUTLIO_DATE_TIME_FORMAT, CUTLIO_LOCAL_TIME_FORMAT, CUTLIO_TIME_FORMAT } from "../../Application/Contants";

const initialState: IAppointmentDetailsState = {
  preventFetch: false,
  request: {
    id: 0,
    note: "",
    services: [],
    currency: "",
    locationId: 0,
    walkIn: false,
    clientNote: "",
    totalAmount: 0,
    recurring: false,
    clientId: undefined,
    amountPaid: undefined,
    clientName: undefined,
    phoneNumber: undefined,
    profilePhoto: undefined,
    status: EAppointmentStatusEnum.CONFIRMED,
    date: format(new Date(), CUTLIO_DATE_FORMAT),
  }
}

const _setWalkInFalse: CaseReducer<IAppointmentDetailsState> = (state: IAppointmentDetailsState) => {
  state.request.walkIn = false;
  state.request.clientId = undefined;
  state.request.clientName = undefined;
  state.request.phoneNumber = undefined;
  state.request.profilePhoto = undefined;
}

const _setIsWalkInTrue: CaseReducer<IAppointmentDetailsState> = (state: IAppointmentDetailsState) => {
  state.request.walkIn = true;
  state.request.clientId = undefined;
  state.request.clientName = undefined;
  state.request.phoneNumber = undefined;
  state.request.profilePhoto = undefined;
}

const _resetAppointmentState: CaseReducer<IAppointmentDetailsState> = (state: IAppointmentDetailsState) => {
  state.preventFetch = false
  state.request = initialState.request
}

/**
 * Sets appointment date
 * @param state
 * @param action
 */
const _setAppointmentDate: CaseReducer<IAppointmentDetailsState, PayloadAction<Date>> = (state: IAppointmentDetailsState, action: PayloadAction<Date>) => {
  state.request.date = format(action.payload, CUTLIO_DATE_FORMAT)
  state.request.services.forEach(service => service.date = format(action.payload, CUTLIO_DATE_FORMAT))
}

/**
 * Sets client walk in name
 * @param state
 * @param action
 */
const _setWalkClientName: CaseReducer<IAppointmentDetailsState, PayloadAction<string>> = (state: IAppointmentDetailsState, action: PayloadAction<string>) => {
  state.request.clientName = action.payload
}

/**
 * Prevent fetch
 * @param state
 * @param action
 */
const _setPreventFetch: CaseReducer<IAppointmentDetailsState, PayloadAction<boolean>> = (state: IAppointmentDetailsState, action: PayloadAction<boolean>) => {
  state.preventFetch = action.payload
}

/**
 * Sets appointment note
 * @param state
 * @param action
 */
const _setAppointmentPublicNote: CaseReducer<IAppointmentDetailsState, PayloadAction<string>> = (state: IAppointmentDetailsState, action: PayloadAction<string>) => {
  state.request.note = action.payload
}


/**
 * Sets selected client
 * @param state
 * @param action
 */
const _setSelectedClient: CaseReducer<IAppointmentDetailsState, PayloadAction<IClientListItem>> = (state: IAppointmentDetailsState, action: PayloadAction<IClientListItem>) => {
  state.request.clientId = action.payload.id
  state.request.clientName = action.payload.name
  state.request.phoneNumber = action.payload.phoneNumber
  state.request.profilePhoto = action.payload.profilePhoto
}

/**
 * Sets selected appointment service
 * @param state
 * @param action
 */
const _setSelectedAppointmentService: CaseReducer<IAppointmentDetailsState, PayloadAction<IServiceListItem>> = (state: IAppointmentDetailsState, action: PayloadAction<IServiceListItem>) => {
  const index = action.payload.index;
  const service = state.request.services[index];
  const startTime = parse(`${service.date} ${service.startTime}`, CUTLIO_DATE_TIME_FORMAT, new Date())

  state.request.services[index] = {
    ...service,
    serviceId: action.payload.id,
    currency: action.payload.currency,
    serviceName: action.payload.serviceName,
    serviceDuration: action.payload.estimatedDuration,
    endTime: format(addMinutes(startTime, action.payload.estimatedDuration), CUTLIO_TIME_FORMAT),
    localizedEndTime: format(addMinutes(startTime, action.payload.estimatedDuration), CUTLIO_TIME_FORMAT)
  }
}

/**
 * Sets appointment selection information
 * @param state
 * @param action
 */
const _setAppointmentSelectionInfo: CaseReducer<IAppointmentDetailsState, PayloadAction<IExtendedSelectionInfo>> = (state: IAppointmentDetailsState, action: PayloadAction<IExtendedSelectionInfo>) => {
  state.request.locationId = action.payload.locationId
  state.request.date = format(action.payload.start, CUTLIO_DATE_FORMAT)
  state.request.services = [
    {
      id: 0,
      price: 0,
      serviceId: 0,
      currency: "",
      serviceName: "",
      employeeName: "",
      serviceDuration: 0,
      employeeProfilePhoto: "",
      locationId: action.payload.locationId,
      status: EAppointmentStatusEnum.CONFIRMED,
      employeeId: Number(action.payload?.resource?.id || 0),
      date: format(action.payload.start, CUTLIO_DATE_FORMAT),
      endTime: format(action.payload.end, CUTLIO_TIME_FORMAT),
      startTime: format(action.payload.start, CUTLIO_TIME_FORMAT),
      localizedEndTime: format(action.payload.end, CUTLIO_LOCAL_TIME_FORMAT),
      localizedStartTime: format(action.payload.start, CUTLIO_LOCAL_TIME_FORMAT),
    }
  ]
}

const _setAppointmentDetails: CaseReducer<IAppointmentDetailsState, PayloadAction<IGetAppointmentResponse>> = (state: IAppointmentDetailsState, action: PayloadAction<IGetAppointmentResponse>) => {
  if (!action.payload?.partnerAppointment?.response) return
  const {clientName, clientId} = action.payload.partnerAppointment.response
  state.request = {...action.payload.partnerAppointment.response, walkIn: Boolean(!clientId && clientName)}
}

/**
 * Sets appointment service end time
 * @param state
 * @param action
 */
const _setAppointmentServiceEndTime: CaseReducer<IAppointmentDetailsState, PayloadAction<IAppointmentServiceTimeChange>> = (state: IAppointmentDetailsState, action: PayloadAction<IAppointmentServiceTimeChange>) => {
  state.request.services[action.payload.index].endTime = format(action.payload.date, CUTLIO_TIME_FORMAT)
  state.request.services[action.payload.index].localizedEndTime = format(action.payload.date, CUTLIO_LOCAL_TIME_FORMAT)
}

/**
 * Sets appointment service start time
 * @param state
 * @param action
 */
const _setAppointmentServiceStartTime: CaseReducer<IAppointmentDetailsState, PayloadAction<IAppointmentServiceTimeChange>> = (state: IAppointmentDetailsState, action: PayloadAction<IAppointmentServiceTimeChange>) => {
  state.request.services[action.payload.index].startTime = format(action.payload.date, CUTLIO_TIME_FORMAT)
  state.request.services[action.payload.index].localizedStartTime = format(action.payload.date, CUTLIO_LOCAL_TIME_FORMAT)
}

const AppointmentSlice = createSlice({
  name: 'appointment',
  initialState: initialState,
  reducers: {
    setIsWalkInTrue: _setIsWalkInTrue,
    setIsWalkInFalse: _setWalkInFalse,
    setPreventFetch: _setPreventFetch,
    setSelectedClient: _setSelectedClient,
    setWalkClientName: _setWalkClientName,
    setAppointmentDate: _setAppointmentDate,
    setAppointmentDetails: _setAppointmentDetails,
    resetAppointmentState: _resetAppointmentState,
    setAppointmentPublicNote: _setAppointmentPublicNote,
    setAppointmentSelectionInfo: _setAppointmentSelectionInfo,
    setSelectedAppointmentService: _setSelectedAppointmentService,
    setAppointmentServiceEndTime: _setAppointmentServiceEndTime,
    setAppointmentServiceStartTime: _setAppointmentServiceStartTime,
  }
})

export const AppointmentReducer = AppointmentSlice.reducer;
export const {
  setIsWalkInTrue,
  setPreventFetch,
  setIsWalkInFalse,
  setWalkClientName,
  setSelectedClient,
  setAppointmentDate,
  resetAppointmentState,
  setAppointmentDetails,
  setAppointmentPublicNote,
  setAppointmentSelectionInfo,
  setSelectedAppointmentService,
  setAppointmentServiceEndTime,
  setAppointmentServiceStartTime,
} = AppointmentSlice.actions;
