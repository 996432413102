import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  isWalkIn: boolean
  toggleWalkIn: () => void
}

export const WalkInButton = ({isWalkIn, toggleWalkIn}: Props) => {
  const intl = useIntl()
  const color = isWalkIn ? "var(--falcon-blue)" : "var(--falcon-gray-500)";
  const icon: IconProp = isWalkIn ? ["fas", "square-check"] : ["far", "square"];

  return (
    <StyledHolder>
      <StyledLabel>{intl.formatMessage({id: "appointment.details.select.client.walk.in"})}</StyledLabel>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        fontSize="1.5em"
        onClick={toggleWalkIn}
        className="cursor-pointer pt-1"
      />
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  width: 100%;
  display: flex;
  padding-top: .5rem;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`


const StyledLabel = styled.span`
  font-weight: 600;
  padding-top: .1rem;
  padding-right: .5rem;
  color: var(--falcon-gray-600);
`