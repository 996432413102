import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAppointmentPublicNote } from "../../Redux";

interface Props {
  publicNote?: string
}

export const AppointmentPublicNote = ({publicNote}: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch(setAppointmentPublicNote(event.target.value))
  }

  return (
    <StyledHolder>
      <StyledInput
        rows={3}
        value={publicNote}
        onChange={handleTextChange}
        id="appointment-public-note"
        className="form-control outline-none"
        placeholder={intl.formatMessage({id: "appointment.details.public.note.placeholder"})}
      />
    </StyledHolder>
  )
}

const StyledHolder = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
`

const StyledInput = styled.textarea`
  outline: none;

  &::placeholder {
    font-style: italic;
    font-size: .8444444rem;
    color: var(--falcon-gray-500);
  }
`