import React from 'react'
import cn from "classnames";
import { Button } from 'react-bootstrap'

interface Props {
  id: number;
  title: string;
  selected: boolean;

  onDayItemClick(day: number): void;
}

export const WeekDayItem = ({id, title, selected, onDayItemClick}: Props) => {
  const selectedStyles = selected ? "week-day-item text-white" : "";
  const styles = cn("icon-item btn btn-sm border border-300 btn-falcon-primary", selectedStyles);

  function handleClick() {
    onDayItemClick(id)
  }

  return (
    <Button className={styles} onClick={handleClick}>
      <span className="fs--1 fw-semi-bold text-capitalize">{title}</span>
    </Button>
  )
}