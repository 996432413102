import { gql, useQuery } from "@apollo/client";
import { IEmployeeServiceListRequest, IEmployeeServiceListResponse } from "../Types";

export const GET_EMPLOYEE_SERVICES_QUERY = gql`
    query GetEmployeeServices($locationId: Int!, $employeeId: Int!) {
        employeeServices(locationId: $locationId, employeeId: $employeeId) {
            response {
                id
                price
                index
                currency
                serviceName
                servicePhoto
                priceTypeEnum
                invariantPrice
                branchOfficeId
                estimatedDuration
            }
        }
    }
`

/**
 * Use user appointments query
 * @param locationId
 * @param employeeId
 */
export const useEmployeeServicesQuery = (locationId: number, employeeId: number) =>
  useQuery<IEmployeeServiceListResponse, IEmployeeServiceListRequest>(GET_EMPLOYEE_SERVICES_QUERY, {
      variables: {locationId, employeeId},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first',
    }
  );