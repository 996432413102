import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container, Form, Row } from "react-bootstrap";

interface Props {
  className: string
}

export const AuthenticationControls = ({className}: Props) => {
  const intl = useIntl()

  return (
    <Container>
      <StyledRow className={className}>
        <Form.Check type="checkbox" id="rememberMe" className="flex-1">
          <Form.Check.Input type="checkbox" name="remember" defaultChecked={true}/>
          <Form.Check.Label className="mb-0">{intl.formatMessage({id: "sign.in.form.remember.me.text"})}</Form.Check.Label>
        </Form.Check>
        <StyledLink to="ForgotPassword">{intl.formatMessage({id: 'sign.in.form.forgot.password.text'})}</StyledLink>
      </StyledRow>
    </Container>
  )
}

const StyledRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  flex: 1;
  font-weight: 500;
  cursor: pointer;
  padding-right: 0;
  margin-bottom: 0;
  text-align: right;
  font-size: .9rem;
  text-decoration: none;

  &:hover {
    opacity: .5;
    text-decoration: none;
  }
`