import styled from "styled-components";
import { PropsWithChildren } from "react";
import { Card, Col, Row } from 'react-bootstrap'
import { NewBlockedTimeButton } from "../NewBlockedTime";

interface Props extends PropsWithChildren {
}

export const CalendarHeader = ({children}: Props) => {
  return (
    <Card.Header>
      <StyledHolder>
        <Col xs={4} sm="auto"/>
        <Col xs={4} sm="auto">{children}</Col>
        <Col xs={4} sm="auto">
          <NewBlockedTimeButton/>
        </Col>
      </StyledHolder>
    </Card.Header>
  )
}


const StyledHolder = styled(Row)`
  justify-content: space-between;
`