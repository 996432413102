import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApplicationState, IRefreshToken, IUserCredentials } from "../Types";
import { LocalizationEnum } from "../../../Cutlio.Providers/LocalizationProvider";

const initialState: IApplicationState = {
  email: '',
  validTo: '',
  firstName: '',
  lastName: '',
  accessToken: '',
  refreshToken: '',
  expirationInSec: '',
  isAppLoading: false,
  needsPhoneAuth: true,
  isAuthenticated: false,
  profilePhoto: undefined,
  deviceIdentifier: uuidv4(),
  currentLanguage: LocalizationEnum.EnglishUnitedStated,
}

// App login in
const _applicationSignIn: CaseReducer<IApplicationState, PayloadAction<IUserCredentials>> = (state: IApplicationState, action: PayloadAction<IUserCredentials>) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.accessToken}`;
  state.refreshToken = action.payload.refreshToken;
  state.profilePhoto = action.payload.profilePhoto;
  state.accessToken = action.payload.accessToken;
  state.firstName = action.payload.firstName;
  state.lastName = action.payload.lastName;
  state.email = action.payload.email
  state.isAuthenticated = true;
}

const _applicationSetLanguage: CaseReducer<IApplicationState, PayloadAction<string>> = (state: IApplicationState, action: PayloadAction<string>) => {
  state.currentLanguage = action.payload;
}

const _setRefreshTokenResponse: CaseReducer<IApplicationState, PayloadAction<IRefreshToken>> = (state: IApplicationState, action: PayloadAction<IRefreshToken>) => {
  state.accessToken = action.payload.accessToken;
  state.refreshToken = action.payload.refreshToken;
}

const _setNeedsPhoneAuth: CaseReducer<IApplicationState, PayloadAction<boolean>> = (state: IApplicationState, action: PayloadAction<boolean>) => {
  state.needsPhoneAuth = action.payload;
}

const _applicationSignOut: CaseReducer<IApplicationState, PayloadAction> = (state: IApplicationState) => {
  axios.defaults.headers.common['Authorization'] = "";
  state.isAuthenticated = false;
  state.needsPhoneAuth = false;
  state.profilePhoto = '';
  state.refreshToken = '';
  state.accessToken = '';
  state.firstName = '';
  state.lastName = '';
  state.email = '';
  state.validTo = '';
}

const ApplicationSlice = createSlice({
  name: 'application',
  initialState: initialState,
  reducers: {
    setNeedsPhoneAuth: _setNeedsPhoneAuth,
    applicationSignIn: _applicationSignIn,
    applicationSignOut: _applicationSignOut,
    applicationSetLanguage: _applicationSetLanguage,
    setRefreshTokenResponse: _setRefreshTokenResponse,
  }
})

export const ApplicationReducer = ApplicationSlice.reducer;
export const {
  applicationSignIn,
  applicationSignOut,
  applicationSetLanguage,
  setNeedsPhoneAuth,
  setRefreshTokenResponse
} = ApplicationSlice.actions;