import React from 'react'
import cn from "classnames";
import { useIntl } from "react-intl";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { addYears, format, parse } from "date-fns";
import Select, { ControlProps, CSSObjectWithLabel, StylesConfig } from "react-select";
import { useRepeatOptions } from "../../Options";
import { ISelectOption } from "../../../../Cutlio.Types/Select";
import { CUTLIO_DATE_FORMAT } from "../../../Application/Contants";
import { useLocalizationContext } from "../../../../Cutlio.Providers/LocalizationProvider";

interface Props {
  repeatEvery: number
  repeatTo: string | undefined

  setRepeatTo(date: Date): void;

  setRepeatEvery(item: ISelectOption<number> | null): void
}

export const RepeatUntil = ({repeatEvery, repeatTo, setRepeatEvery, setRepeatTo}: Props) => {
  const intl = useIntl()
  const options = useRepeatOptions()
  const {currentLocale} = useLocalizationContext()
  const selectedItem = options.find(option => option.value === repeatEvery)

  function parseDate() {
    const defaultDate = format(new Date(), CUTLIO_DATE_FORMAT);
    return parse(repeatTo || defaultDate, CUTLIO_DATE_FORMAT, new Date())
  }

  return (
    <StyledContainer>
      <StyledWrapperLeft>
        <Select
          options={options}
          value={selectedItem}
          styles={DropdownStyles}
          onChange={setRepeatEvery}
          placeholder={intl.formatMessage({id: 'appointment.details.select.service.placeholder'})}
        />
      </StyledWrapperLeft>
      <StyledWrapperRight>
        <DatePicker
          dateFormat="PPP"
          timeIntervals={15}
          minDate={new Date()}
          calendarStartDay={1}
          selected={parseDate()}
          locale={currentLocale}
          onChange={setRepeatTo}
          maxDate={addYears(new Date(), 1)}
          onKeyDown={(event: any) => event.preventDefault()}
          className={cn("form-control cursor-pointer text-capitalize", {"d-none": selectedItem?.value === 0})}
        />
      </StyledWrapperRight>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  padding-top: .75rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledWrapperLeft = styled.div`
  flex: 1;
  padding-right: .5rem;
`

const StyledWrapperRight = styled.div`
  flex: 1;
  padding-left: .5rem;
`

const DropdownStyles: StylesConfig<ISelectOption<number>, false> = {
  control: (provided: CSSObjectWithLabel, state: ControlProps<ISelectOption<number>>) => {
    return {
      ...provided,
      cursor: "pointer !important",
      boxShadow: state.isFocused ? "var(--falcon-primary)" : "var(--falcon-gray-300)",
      '&:hover': {
        border: "1px solid var(--falcon-gray-900)"
      },
      border: state.isFocused ? "1px solid var(--falcon-gray-900)" : "1px solid var(--falcon-gray-300)"
    }
  },
  option: styles => ({
    ...styles,
    cursor: 'pointer',
  }),
  placeholder: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      opacity: .6,
      fontWeight: 500,
      fontStyle: 'italic',
      fontSize: '.844444444444rem',
      color: 'var(--falcon-gray-600)',
    }
  }
}