import React from 'react'
import styled from "styled-components";
import { Container, Spinner } from "react-bootstrap";

interface Props {
  show: boolean
}

export const CardLoader = ({show}: Props) => {
  return (
    <StyledLoader hidden={!show}>
      <Spinner animation="border" size="sm" variant="secondary"/>
    </StyledLoader>
  )
}

const StyledLoader = styled(Container)`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
`