import React from 'react'
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { ScaleLoader } from "react-spinners";
import styles from './loader.module.css';


interface Props {
  show: boolean
}

export const BusyIndicator = ({show}: Props) => {
  return (
    <Modal contentClassName={styles.content} show={show} size="sm" backdropClassName={styles.backdrop}>
      <StyledWrapper>
        <StyledHolder className={styles.container}>
          <ScaleLoader color="white"/>
        </StyledHolder>
      </StyledWrapper>
    </Modal>
  )
}


const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledHolder = styled.div`
  padding: 2rem 2.5rem;
  border-radius: .75rem;
  background: rgba(0, 0, 0, .5);
`