import React from 'react'
import cn from "classnames";
import Avatar from "react-avatar";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { ResourceLabelContentArg } from "@fullcalendar/resource";

interface Props {
  item: ResourceLabelContentArg
}

export const ResourceHeader = ({item}: Props) => {
  const intl = useIntl()
  const resource = item?.resource;
  const isWorking: boolean = resource.extendedProps?.isWorking;

  const notWorkingText = intl.formatMessage({id: "appointment.list.view.resource.header.not.working"});

  return (
    <StyledHolder>
      <Avatar round={true} size="2.4rem" name={resource?.title} src={resource?.extendedProps?.profilePhoto}/>
      <StyledInfoHolder>
        <StyledTitle>{resource.title}</StyledTitle>
        <StyledHoursHolder>
          <StyledNotWorkingText className={cn({'d-none': isWorking})}>{notWorkingText}</StyledNotWorkingText>
          <StyledWorkingText className={cn("fs-10", {'d-none': !isWorking})}>
            <StyledText>{resource?.extendedProps?.localizedStartTime}</StyledText>
            <StyledText>&nbsp;-&nbsp;</StyledText>
            <StyledText>{resource?.extendedProps?.localizedEndTime}</StyledText>
          </StyledWorkingText>
        </StyledHoursHolder>
      </StyledInfoHolder>
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  display: flex;
  cursor: pointer;
  padding-top: .2em;
  align-items: center;
  padding-bottom: .2em;
  align-self: flex-start;
`

const StyledInfoHolder = styled.div`
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  align-items: flex-start;
`

const StyledTitle = styled.div`
  font-size: .85em;
  font-weight: 500;
`

const StyledHoursHolder = styled.div`
  font-size: .85em;
  font-weight: 700;
  margin-top: -2px;
`

const StyledNotWorkingText = styled.span`
  font-size: .85em;
  font-weight: 700;
  margin-top: -2px;
  font-style: italic;
`

const StyledWorkingText = styled.div`
  font-weight: 700;
  color: var(--falcon-gray-600);
`

const StyledText = styled.span`
`