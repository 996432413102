import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setWalkClientName } from "../../Redux";

interface Props {
  show: boolean
  value: string
}

export const NoReservationInput = ({show, value}: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const placeholder = intl.formatMessage({id: "appointment.details.select.client.no.reservation.input.placeholder"})

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setWalkClientName(event.target.value))
  }

  return (
    <StyledHolder hidden={!show} as="div" className="search-box">
      <Form className="position-relative">
        <StyledInput type="search" value={value} placeholder={placeholder} className="search-input" onChange={handleTextChange}/>
        <FontAwesomeIcon icon="user" className="position-absolute text-400 search-box-icon"/>
      </Form>
    </StyledHolder>
  )
}

const StyledHolder = styled(Container)`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 2rem;
`

const StyledInput = styled(Form.Control)`
  border-radius: .5rem !important;
`