import { IEmployeeListItem } from "../Types";
import { useIntl } from "react-intl";

/**
 * Determines which status to render
 * @param employee
 */
export function useEmployeeStatusClassName(employee: IEmployeeListItem) {
  return employee.canOnlineBook ? "status-online" : 'status-offline'
}


/**
 * Employee title
 * @param employee
 */
export function useEmployeeTitle(employee: IEmployeeListItem) {
  const intl = useIntl()
  return !!employee.title ? employee.title : intl.formatMessage({id: "employee.list.employee.missing.title"})
}

export function useEmployeePhoneNumber(employee: IEmployeeListItem) {
  const intl = useIntl()
  const phoneNumber = `${intl.formatMessage({id: "employee.list.employee.phone.number"})} : ${employee.phoneNumber}`;
  return !!employee.phoneNumber ? phoneNumber : intl.formatMessage({id: "employee.list.employee.missing.phone.number"});
}