import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { IGetAppointmentRequest, IGetAppointmentResponse } from '../Types'

export const GET_APPOINTMENT_DETAILS_QUERY = gql`
    query GetAppointmentQuery($appointmentId: Int!) {
        partnerAppointment(id: $appointmentId) {
            response {
                id
                status
                note
                date
                recurring
                currency
                frequency
                repeatsTo
                clientId
                locationId
                amountPaid
                totalAmount
                clientNote
                clientName
                phoneNumber
                profilePhoto
                services {
                    id
                    date
                    price
                    status
                    endTime
                    currency
                    startTime
                    serviceId
                    employeeId
                    locationId
                    serviceName
                    employeeName
                    serviceDuration
                    localizedEndTime
                    localizedStartTime
                    employeeProfilePhoto
                }
            }
        }
    }
`

/**
 * Use user locations query
 */
export const useAppointmentDetailsQuery = (appointmentId: number, options?: QueryHookOptions<IGetAppointmentResponse, IGetAppointmentRequest>) =>
  useQuery<IGetAppointmentResponse, IGetAppointmentRequest>(GET_APPOINTMENT_DETAILS_QUERY, {
      variables: {appointmentId: appointmentId},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      skip: appointmentId === 0,
      ...options,
    }
  );