import React from 'react'
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { FetchResult } from "@apollo/client";
import { ISignRequest } from "../Types";
import { LoginView } from "../Views/LoginView";
import { LoginValidationSchema } from "../Validation";
import { IUserCredentials } from "../../Application/Types";
import { applicationSignIn } from "../../Application/Redux";
import { ISignInResponse, useSignInMutation } from "../Mutations";
import OneSignal from "react-onesignal";

const DEFAULT_STATE: ISignRequest = {
  email: "",
  password: ""
}

export const LoginContainer = () => {
  const dispatch = useDispatch();
  const [signIn] = useSignInMutation();

  function handleSubmitAsync(values: ISignRequest, actions: FormikHelpers<ISignRequest>) {
    signIn({variables: {password: values.password, email: values.email}})
      .then(handleSuccess)
      .catch((error) => actions.setStatus(error))
      .finally(() => actions.setSubmitting(false));
  }

  function handleSuccess(response: FetchResult<ISignInResponse>) {
    const {accessToken, refreshToken, profilePhoto, firstName, lastName, email} = response.data!.login.partnerAuthenticationPayload?.response;
    dispatch(applicationSignIn({accessToken, refreshToken, profilePhoto, firstName, lastName, email} as IUserCredentials));
    OneSignal.login(email).catch()
  }

  return (
    <Formik
      enableReinitialize
      component={LoginView}
      onSubmit={handleSubmitAsync}
      initialValues={DEFAULT_STATE}
      validationSchema={LoginValidationSchema}
    />
  )
}