import React from 'react'
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { ButtonIcon } from "../../../../Cutlio.Common/Buttons/ButtonIcon";
import { CalendarDatePicker } from "../../../../Cutlio.Common/DatePicker";

interface Props {
  isRange: boolean
  selectedDate: Date
  selectedEndDate: Date,
  onNextClick: () => void
  onPreviousClick: () => void
  onSelectedDateChanged: (date: Date) => void;
}

export const CalendarToolbar = ({onPreviousClick, onNextClick, onSelectedDateChanged, selectedEndDate, selectedDate, isRange}: Props) => {
  const endDate = isRange ? selectedEndDate : selectedDate;

  return (
    <StyledHolder>
      <ButtonIcon
        size="sm"
        className="mx-2"
        icon="chevron-left"
        onClick={onPreviousClick}
        variant="falcon-primary"
      />
      <PickerPortal id="calendar-portal">
        <CalendarDatePicker
          isRange={isRange}
          popperPlacement="bottom"
          selectedEndDate={endDate}
          selectedStartDate={selectedDate}
          onChange={onSelectedDateChanged}
        />
      </PickerPortal>
      <ButtonIcon
        size="sm"
        className="mx-2"
        icon="chevron-right"
        onClick={onNextClick}
        variant="falcon-primary"
      />
    </StyledHolder>
  )
}

const StyledHolder = styled(Col)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`

const PickerPortal = styled.div`
`