import React, { ReactNode } from 'react'
import { Button, ButtonProps } from "react-bootstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends ButtonProps {
  icon: IconProp
  children?: ReactNode
}

export const ButtonIcon = ({icon, color, children, ...rest}: Props) => {
  return (
    <Button {...rest}>
      <FontAwesomeIcon icon={icon} color={color}/>
      <React.Fragment>{children}</React.Fragment>
    </Button>
  )
}