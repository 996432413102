import React from 'react'
import { useIntl } from "react-intl";
import { Nav, Navbar } from "react-bootstrap";
import { NavbarToggle } from "../NavbarToggle";
import { NavigationItem } from "../NavigationItem";
import { NavigationHeader } from "../NavigationHeader";
import { NavigationDivider } from "../NavigationDivider";

interface Props {
  expanded: boolean

  toggleExpanded(): void
}

export const ApplicationNavbar = ({expanded, toggleExpanded}: Props) => {
  const intl = useIntl()

  return (
    <Navbar className="navbar-vertical" variant="light" expand="xxl" expanded={expanded}>
      <div className="d-flex align-items-center">
        <NavbarToggle/>
        <NavigationHeader/>
      </div>
      <Navbar.Collapse id="responsive-navbar-nav">
        <div className="navbar-vertical-content scrollbar vh-100">
          <Nav className="flex-column" as="ul">
            <NavigationItem
              navigateTo="/"
              icon="house"
              toggleNavigation={toggleExpanded}
              label={intl.formatMessage({id: "navigation.item.home"})}
            />
            <NavigationDivider name={intl.formatMessage({id: "navigation.divider.stuff.and.services"})}/>
            <NavigationItem
              navigateTo="/WorkSchedule"
              icon="calendar-days"
              toggleNavigation={toggleExpanded}
              label={intl.formatMessage({id: "navigation.item.work.schedule"})}
            />
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}
