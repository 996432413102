import React from 'react'
import cn from "classnames";
import Avatar from "react-avatar";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { IClientListItem } from "../Types";
import { useClientPhoneNumber, useClientStatusClassName } from "../Hooks";

interface Props {
  client: IClientListItem

  onClick(client: IClientListItem): void
}

export const ClientListItem = ({client, onClick}: Props) => {
  const status = useClientStatusClassName(client)
  const phoneNumber = useClientPhoneNumber(client)
  const nowShowCount = client.numberOfMissedAppointments;

  function handleClick() {
    onClick(client)
  }

  return (
    <StyledContainer className="fs-10" onClick={handleClick}>
      <Avatar
        round
        size="3.5em"
        maxInitials={2}
        name={client.name}
        src={client.profilePhoto}
        className={cn("avatar avatar-3xl cursor-pointer", status)}
      />
      <StyledContentHolder>
        <StyledName>{client.name}</StyledName>
        <StyledTitle>{phoneNumber}</StyledTitle>
        <StyledNumberOfCompleted>
          <FormattedMessage id="client.list.client.number.of.completed"/>:
          <StyledCompletedLabel>{client.numberOfCompleted}</StyledCompletedLabel>
        </StyledNumberOfCompleted>
        <StyledNumberOfMissed>
          <FormattedMessage id="client.list.client.number.of.missed"/>:
          <StyledNumberOfMissedLabel className={cn({"text-danger fw-bold": !!nowShowCount})}>{nowShowCount}</StyledNumberOfMissedLabel>
        </StyledNumberOfMissed>
        <StyledTotalRevenueGenerated>
          <FormattedMessage id="client.list.client.total.revenue.generated"/>:
          <StyledRevenueGeneratedLabel>{client.totalClientRevenue} {client.providerCurrency}</StyledRevenueGeneratedLabel>
        </StyledTotalRevenueGenerated>
        <StyledDivider>&nbsp;</StyledDivider>
      </StyledContentHolder>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
`

const StyledContentHolder = styled.div`
  flex: 1;
  margin-left: .7rem;
  align-content: center;
`

const StyledName = styled.h6`
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0;
  text-transform: capitalize;
  color: var(--falcon-gray-600);

  &:hover {
    color: var(--falcon-gray-600);
  }
`

const StyledTitle = styled.div`
  margin-bottom: .5rem;
  font-style: italic;
  font-size: .833333333rem;
  color: var(--falcon-gray-600);
`

const StyledDivider = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px dashed var(--falcon-border-color);
`

const StyledNumberOfCompleted = styled.div`
  margin-bottom: 0;
  color: var(--falcon-gray-1000);
`

const StyledCompletedLabel = styled.span`
  font-weight: 700;
  padding-left: .25rem;
  padding-right: .25rem;
  color: var(--falcon-success);
`

const StyledNumberOfMissed = styled.span`
  margin-bottom: 0;
`

const StyledNumberOfMissedLabel = styled.span`
  padding-left: .25rem;
  padding-right: .25rem;
`

const StyledTotalRevenueGenerated = styled.div`
  margin-bottom: 0;
  font-size: .8rem;
`

const StyledRevenueGeneratedLabel = styled.span`
  font-weight: 800;
  margin-left: .25rem;
  margin-right: .25rem;
  color: var(--falcon-gray-600);
`