import React, { useState } from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { IPartnerMissedAppointmentInput } from "../../Types";
import { AppointmentCheckbox } from "../AppointmentCheckbox";
import { IRootState } from "../../../../Cutlio.Providers/ApplicationState";
import { PrimaryButton } from "../../../../Cutlio.Common/Buttons/PrimaryButton";
import { GET_APPOINTMENTS_QUERY } from "../../../Calendar/Queries/GetAppointmentsQuery";
import { useMissedAppointmentMutation } from "../../Mutations/MissedAppointmentMutation";
import { ICutlioError, useApolloError } from "../../../../Cutlio.Common/Hooks/Error/ApolloNetworkError";
import { useReservationSidebarContext } from "../../../../Cutlio.Providers/ReservationSidebarProvider/Context";

interface Props {
  show: boolean
  onBackClicked: () => void
}


export const AppointmentMissedWarning = ({show, onBackClicked}: Props) => {
  const intl = useIntl()
  const {toggleSidebar} = useReservationSidebarContext()
  const [blockClient, setBlockClient] = useState(true)
  const [sendNotification, setSendNotification] = useState(true)
  const [missedAppointment, missedAppointmentOptions] = useMissedAppointmentMutation();
  const {id, date, locationId} = useSelector((state: IRootState) => state.appointment.request);
  const cancelError: ICutlioError = useApolloError(missedAppointmentOptions.error, missedAppointmentOptions.loading)

  function handleSubmit() {
    const refetchVariables = {locationId, selectedDate: date};
    const refetchQueries = [{query: GET_APPOINTMENTS_QUERY, variables: refetchVariables}]

    const missedRequest: IPartnerMissedAppointmentInput = {id, blockClient, cancelSeries: false, sendNotification, sendSms: false, collectDeptOnNextAppointment: false}
    missedAppointment({variables: {missedRequest}, awaitRefetchQueries: true, refetchQueries}).then(handleSuccessCancel).catch()
  }

  function toggleBlockClient() {
    setBlockClient(!blockClient)
  }

  function handleSuccessCancel() {
    toggleSidebar()
  }

  function toggleSendNotification() {
    setSendNotification(!sendNotification)
  }

  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <StyledContainer>
        <StyledWrapper>
          <StyledIcon size={45}/>
          <StyledContent>
            <StyledTitle>{intl.formatMessage({id: "appointment.details.actions.no.show.title"})}</StyledTitle>
            <StyledDescription>{intl.formatMessage({id: "appointment.details.actions.no.show.placeholder"})}</StyledDescription>
            <StyledOptionsHolder>
              <AppointmentCheckbox
                hidden={false}
                checked={sendNotification}
                toggleCheckbox={toggleSendNotification}
                label={intl.formatMessage({id: "appointment.details.actions.send.notification"})}
              />
              <AppointmentCheckbox
                hidden={false}
                checked={blockClient}
                toggleCheckbox={toggleBlockClient}
                label={intl.formatMessage({id: "appointment.details.actions.block.client"})}
              />
            </StyledOptionsHolder>
            <StyledError hidden={!cancelError.showError}>{cancelError.errorMessage}</StyledError>
          </StyledContent>
        </StyledWrapper>
      </StyledContainer>
      <StyledFooter>
        <Button className="me-2" size="sm" variant="outline" onClick={onBackClicked}>
          {intl.formatMessage({id: "common.form.elements.back"})}
        </Button>
        <PrimaryButton
          size="sm"
          variant="danger"
          onClick={handleSubmit}
          isBusy={missedAppointmentOptions.loading}
          title={intl.formatMessage({id: "appointment.details.actions.no.show"})}
        />
      </StyledFooter>
    </Modal>
  )
}

const StyledContainer = styled(Modal.Body)`
`

const StyledWrapper = styled.div`
  display: flex;
`

const StyledIcon = styled(BsFillExclamationTriangleFill)`
  color: var(--falcon-danger);
`

const StyledContent = styled.div`
  width: 100%;
  padding-top: .5rem;
  padding-left: .75rem;
`

const StyledTitle = styled.h5`
  font-size: 1.2rem;
  font-weight: 600;
`

const StyledFooter = styled(Modal.Footer)`
  border-top: none;
  background: var(--falcon-light);
`

const StyledError = styled.p`
  font-weight: 600;
  font-size: .9rem;
  margin-top: .75rem;
  border-radius: .375rem;
  color: var(--falcon-danger);
`

const StyledDescription = styled.p`
  opacity: .8;
  font-size: .8rem;
  font-weight: 500;
  font-style: italic;
  color: var(--falcon-gray-600);
`

const StyledOptionsHolder = styled.div`
  display: flex;
  flex-direction: column;
`