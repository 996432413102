import React from 'react'
import styled from "styled-components";
import { useIntl } from "react-intl";
import { EventContentArg } from "@fullcalendar/core";
import { StatusIndicator } from "../StatusIndicator";
import { useEventStyles } from "../../Hooks/EventStyles";
import { useEventDisplayTime } from "../../Hooks/EventTime";
import { EAppointmentStatusEnum } from "../../Enums";
import { useReservationSidebarContext } from "../../../../Cutlio.Providers/ReservationSidebarProvider/Context";

interface Props {
  eventInfo: EventContentArg
}


export const AllDayEvent = ({eventInfo}: Props) => {
  const intl = useIntl()
  const eventStyles = useEventStyles(eventInfo)
  const eventTime = useEventDisplayTime(eventInfo)
  const {status} = eventInfo?.event.extendedProps;
  const bulletStyle = {backgroundColor: eventStyles.color};
  const {openAppointmentDetails, openBlockedTimeDetails} = useReservationSidebarContext()

  function handleClick() {
    const id = eventInfo.event.groupId;
    return status === EAppointmentStatusEnum.TIME_RESERVATION ? openBlockedTimeDetails(id) : openAppointmentDetails(id);
  }

  return (
    <StyleEventHolder style={eventStyles} onClick={handleClick}>
      <StyledWrapper>
        <StyledTimeHolder>
          <StyledBullet style={bulletStyle}/>
          <StyledTimeText>{intl.formatMessage({id: "appointment.list.view.event.time.break"})} {eventTime}</StyledTimeText>
        </StyledTimeHolder>
        <StyledClientInfoHolder>
          <StyledTitleText className="fs-10">{eventInfo.event.title}</StyledTitleText>
        </StyledClientInfoHolder>
      </StyledWrapper>
      <StatusIndicator status={status}/>
    </StyleEventHolder>
  )
}


const StyleEventHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  min-height: 4rem;
  padding-top: .15em;
  padding-left: .3em;
  border-radius: .15rem;
  border-left: .25rem solid;
`

const StyledWrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding-top: .25rem;
  padding-left: .25rem;
  white-space: nowrap;
  flex-direction: column;
  text-overflow: ellipsis;
`

const StyledTimeHolder = styled.div`
  display: flex;
  color: inherit;
  font-size: .85rem;
  font-weight: 700;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
`

const StyledBullet = styled.div`
  width: .4em;
  height: .4em;
  border-radius: 50%;
  margin-right: .4em;
  background-color: inherit;
`

const StyledTimeText = styled.span`
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`

const StyledClientInfoHolder = styled.div`
  margin-top: -4px;
  padding-left: .5rem;
`

const StyledTitleText = styled.span`
  color: inherit;
  font-weight: 600;
  padding-left: .25rem;
`