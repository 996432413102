import React from 'react'
import { Dropdown } from "react-bootstrap";
import { useIntl } from "react-intl";
import { LanguageMenuItem } from "../LanguageMenuItem";
import { LocalizationEnum, useLocalizationContext } from "../../Cutlio.Providers/LocalizationProvider";

export const LanguageMenu = () => {
  const intl = useIntl()
  const languages = Object.values(LocalizationEnum);
  const {setCurrentLanguage, currentLanguage} = useLocalizationContext()

  function filterSupportedLanguages() {
    const notSupported = [LocalizationEnum.MacedonianDefault, LocalizationEnum.GermanDefault, LocalizationEnum.GermanAustria, LocalizationEnum.MontenegroLatin];
    return languages.filter(language => !notSupported.includes(language));
  }

  return (
    <Dropdown navbar as="li" className="pe-2">
      <Dropdown.Toggle bsPrefix="toggle" as="a" className="nav-link dropdown-toggle d-flex align-items-center pe-1 cursor-pointer">
        {intl.formatMessage({id: `choose.language.list.item.${currentLanguage}`})}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        {
          filterSupportedLanguages().map((language: LocalizationEnum) =>
            <LanguageMenuItem
              key={language}
              language={language}
              currentLanguage={currentLanguage}
              onLanguageItemClicked={setCurrentLanguage}
              localizedLangauge={intl.formatMessage({id: `choose.language.list.item.${language}`})}
            />
          )
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}
