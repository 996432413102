import { useIntl } from "react-intl";
import { EServicePriceType } from "../Enums";

export function useServicePriceType(priceType: EServicePriceType) {
  const intl = useIntl()
  
  if (priceType === EServicePriceType.FIXED) return intl.formatMessage({id: "service.list.item.fixed.price"})
  if (priceType === EServicePriceType.VARIES) return intl.formatMessage({id: "service.list.item.variable.price"})
  if (priceType === EServicePriceType.STARTS_FROM) return intl.formatMessage({id: "service.list.item.price.starts.from"})
  if (priceType === EServicePriceType.AUTO_CALCULATED) return intl.formatMessage({id: "service.list.item.price.auto.calculated"})
}