import { useIntl } from "react-intl";
import { ApolloError, ServerError } from "@apollo/client";

export interface ICutlioError {
  showError: boolean;
  errorMessage: string | undefined;
}

export function useApolloError(error: ApolloError | undefined, loading: boolean): ICutlioError {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({id: "common.message.generic.error"})
  const defaultResponse = {showError: true, errorMessage: defaultMessage}

  function handleServerErrorResponse(networkError: ServerError): ICutlioError {
    if (!networkError?.result) return defaultResponse
    if (typeof networkError.result === 'string') return defaultResponse
    if ('errors' in networkError.result) return {showError: true, errorMessage: networkError.result.errors[0].message || defaultMessage}
    return defaultResponse
  }

  if (!error) return {showError: false, errorMessage: undefined};
  if (loading) return {showError: false, errorMessage: undefined};
  if (!error?.networkError) return {showError: true, errorMessage: defaultMessage};
  if (error.networkError as ServerError) return handleServerErrorResponse(error.networkError as ServerError);
  if (!!error.message) return {showError: true, errorMessage: error.message};

  else return {showError: true, errorMessage: defaultMessage};
}

