import React from 'react'
import cn from "classnames";
import Avatar from "react-avatar";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRootState } from "../../../../Cutlio.Providers/ApplicationState";

interface Props {
  show: boolean
}

export const AppointmentClientName = ({show}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {request} = useSelector((state: IRootState) => state.appointment)
  const phoneNumber = request?.phoneNumber || intl.formatMessage({id: "appointment.details.client.phone.number.missing"});

  function handleClick() {
    navigate('/client')
  }

  return (
    <StyledHolder as="div" hidden={!show} onClick={handleClick}>
      <StyledInformationHolder>
        <StyledWrapper>
          <Avatar
            round
            size="2.3rem"
            maxInitials={2}
            name={request.clientName}
            src={request.profilePhoto}
            className={cn("cursor-pointer")}
          />
        </StyledWrapper>
        <StyledNameAndPhoneHolder>
          <StyledClientName>{request.clientName}</StyledClientName>
          <StyledPhoneNumber>{phoneNumber}</StyledPhoneNumber>
        </StyledNameAndPhoneHolder>
      </StyledInformationHolder>
      <StyledArrowIcon icon="chevron-right"/>
    </StyledHolder>
  )
}

const StyledHolder = styled(Container)`
  width: 100%;
  display: flex;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-left: .5rem;
  padding-right: .5rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

const StyledWrapper = styled.div`
  padding: .1rem;
  --falcon-bg-opacity: 1;
  background-color: var(--falcon-gray-500);
  border-radius: var(--falcon-border-radius-pill);
`

const StyledClientName = styled.h6`
  font-size: 1.1rem;
  margin-top: -3px;
  font-weight: 600;
  padding-top: .1rem;
  color: var(--falcon-gray-600);
`

const StyledInformationHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledArrowIcon = styled(FontAwesomeIcon)`
  color: var(--falcon-gray-500);
`

const StyledNameAndPhoneHolder = styled.div`
  display: flex;
  padding-left: .5rem;
  flex-direction: column;
`

const StyledPhoneNumber = styled.div`
  font-size: .8rem;
  font-weight: 500;
  margin-top: -8px;
  font-style: italic;
  color: var(--falcon-gray-600);
`

