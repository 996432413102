import React from 'react'
import Avatar from "react-avatar";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";
import OneSignal from "react-onesignal";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../Cutlio.Providers/ApplicationState";
import { applicationSignOut } from "../../Cutlio.Containers/Application/Redux";

export const NavigationUserMenu = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {firstName, lastName, profilePhoto} = useSelector((state: IRootState) => state.application)
  const fullName = `${firstName} ${lastName}`

  function handleSignOut() {
    OneSignal.logout().catch()
    dispatch(applicationSignOut())
  }

  return (
    <Dropdown navbar as="li">
      <Dropdown.Toggle bsPrefix="toggle" as="a" className="pe-0 ps-2 nav-link cursor-pointer">
        <StyledAvatar size="35" round name={fullName} src={profilePhoto as any} maxInitials={2}/>
      </Dropdown.Toggle>
      <StyledContainer className="dropdown-menu-end">
        <StyledItemHolder>
          <Dropdown.Item as={Link} to="/" onClick={handleSignOut}>{intl.formatMessage({id: "sign.out.form.title"})}</Dropdown.Item>
        </StyledItemHolder>
      </StyledContainer>
    </Dropdown>
  )
}

const StyledContainer = styled(Dropdown.Menu)`
  padding: 0;
  --bs-position: end;

  &:after {
    content: "";
    width: 1rem;
    z-index: -1;
    height: 1rem;
    left: 1.8rem;
    top: -0.375rem;
    position: absolute;
    background: inherit;
    border-style: solid;
    border-radius: 0.125rem;
    transform: rotate(45deg);
    border-width: 1px 0 0 1px;
    box-shadow: var(--falcon-box-shadow-sm);
    border-color: var(--falcon-dropdown-border-color);
  }
`

const StyledAvatar = styled(Avatar)`
  margin-top: -1px;
`

const StyledItemHolder = styled.div`
  padding-top: .5rem;
  padding-bottom: .5rem;
  background: var(--falcon-white);
  border-radius: var(--falcon-border-radius)
`
