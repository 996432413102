/**
 * Date format
 */
export const CUTLIO_DATE_FORMAT = "yyyy-MM-dd";

/**
 * Time format
 */
export const CUTLIO_TIME_FORMAT = "HH:mm"

/**
 * Local time format
 */
export const CUTLIO_LOCAL_TIME_FORMAT = "p";

/**
 * date time format
 */
export const CUTLIO_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";