import { InMemoryCache } from "@apollo/client";

/**
 * Cutlio cache
 */
export const CutlioCache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    GetWebCalendarResourceQueryDto: {
      fields: {
        businessHours: {
          merge(_: [], incoming: []) {
            return incoming;
          }
        }
      }
    }
  }
})