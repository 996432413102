import React, { useState } from 'react'
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { MainContent } from "../../../Cutlio.Common/Content";
import { ApplicationNavbar } from "../../../Cutlio.Common/Navbar";
import { NotificationProvider } from "../../NotificationProvider";
import { LanguageMenu } from "../../../Cutlio.Common/LanguageMenu";
import { MainNavigationBar } from "../../../Cutlio.Common/NavigationBar";
import { CurrentLocationProvider } from "../../LocationsProvider/Provider";
import { NavigationUserMenu } from "../../../Cutlio.Common/NavigationUserMenu";
import { ReservationSidebarProvider } from "../../ReservationSidebarProvider/Provider";
import { CalendarContainer } from "../../../Cutlio.Containers/Calendar/Container/CalendarContainer";
import { EmployeeListContainer } from "../../../Cutlio.Containers/Employees/Container/EmployeeList";
import { AppointmentContainer } from "../../../Cutlio.Containers/Appointment/Containers/AppointmentContainer";
import { BlockedTimeContainer } from "../../../Cutlio.Containers/BlockedTime/Container/BlockedTimeContainer";
import { UserLocationsContainer } from "../../../Cutlio.Containers/UserLocations/Container/UserLocationsContainer";
import { SelectClientContainer } from "../../../Cutlio.Containers/Client/Container/SelectClient/SelectClientContainer";
import { WorkScheduleListContainer } from "../../../Cutlio.Containers/WorkSchedule/Container/WorkScheduleListContainer";

export const AdminLayout = () => {
  const [expanded, setExpanded] = useState(false)
  const toggleMenu = () => setExpanded(!expanded)

  return (
    <CurrentLocationProvider>
      <ReservationSidebarProvider>
        <NotificationProvider>
          <Container fluid>
            <ApplicationNavbar expanded={expanded} toggleExpanded={toggleMenu}/>
            <MainContent>
              <MainNavigationBar toggleMenu={toggleMenu}>
                <UserLocationsContainer/>
                <LanguageMenu/>
                <NavigationUserMenu/>
              </MainNavigationBar>
              <Routes>
                <Route path="/" element={<CalendarContainer/>}>
                  <Route path="client" element={<SelectClientContainer/>}/>
                  <Route path="appointment" element={<AppointmentContainer/>}/>
                  <Route path="appointment/:id" element={<AppointmentContainer/>}/>
                  <Route path="blocked-time/" element={<BlockedTimeContainer/>}/>
                  <Route path="blocked-time/:id" element={<BlockedTimeContainer/>}/>
                </Route>
                <Route path="/Employees">
                  <Route index element={<EmployeeListContainer/>}/>
                </Route>
                <Route path="/WorkSchedule">
                  <Route index element={<WorkScheduleListContainer/>}/>
                </Route>
              </Routes>
            </MainContent>
          </Container>
        </NotificationProvider>
      </ReservationSidebarProvider>
    </CurrentLocationProvider>
  );
}