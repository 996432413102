import React from 'react'
import Avatar from "react-avatar";
import styled from "styled-components";
import { OptionProps } from 'react-select'
import { FormattedMessage, useIntl } from "react-intl";
import { IEmployeeListItem } from "../Types";


export const SelectEmployeeOptionItem = ({data, innerRef, innerProps}: OptionProps<IEmployeeListItem>) => {
  const intl = useIntl()
  const name = `${data.firstName} ${data.lastName}`
  const titleDescription = intl.formatMessage({id: "employee.list.employee.missing.title"})
  const phoneDescription = intl.formatMessage({id: 'employee.list.employee.missing.phone.number'})

  return (
    <StyledContainer className="fs-10" ref={innerRef} {...innerProps}>
      <StyledAvatarHolder>
        <Avatar
          round
          size="2.75rem"
          name={name}
          maxInitials={2}
          src={data.profilePhoto}
          className="avatar avatar-3xl cursor-pointer"
        />
      </StyledAvatarHolder>
      <StyledContentHolder>
        <StyledServiceName className="text-truncate">{name}</StyledServiceName>
        <StyledTitle>{!data.title ? titleDescription : data.title}</StyledTitle>
        <StyledPhoneNumberHolder>
          <FormattedMessage id="employee.list.employee.phone.number"/>:
          <StyledPhoneNumberLabel>{!data.phoneNumber ? phoneDescription : data.phoneNumber}</StyledPhoneNumberLabel>
        </StyledPhoneNumberHolder>
        <StyledDivider>&nbsp;</StyledDivider>
      </StyledContentHolder>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  cursor: pointer;
  max-width: 100%;
  overflow-x: hidden;
  padding-right: 1rem;
  padding-left: .75rem;
  padding-top: 1.25rem;

  &:hover {
    background: var(--falcon-gray-200);
  }
`

const StyledAvatarHolder = styled.div`
`

const StyledContentHolder = styled.div`
  flex: 1;
  margin-left: .5rem;
  align-content: center;
`

const StyledServiceName = styled.span`
  font-weight: 600;
  display: block;
  width: calc(85%);
  max-width: calc(85%);
  font-size: .833333333rem;
  color: var(--falcon-gray-600);
`

const StyledTitle = styled.div`
  font-size: .8rem;
  margin-top: -3px;
  font-style: italic;
  margin-bottom: .5rem;
  color: var(--falcon-gray-600);
`

const StyledPhoneNumberHolder = styled.div`
  margin-bottom: 0;
  color: var(--falcon-gray-600);
`

const StyledPhoneNumberLabel = styled.span`
  font-weight: 600;
  padding-left: .25rem;
  padding-right: .25rem;
  color: var(--falcon-gray-600);
`

const StyledDivider = styled.div`
  border-bottom: 1px dashed var(--falcon-border-color);
`
