import React from 'react'
import { Link } from "react-router-dom";
import Logo from '../../Cutlio.Assets/Logo/CutlioLogo.png'

export const NavigationHeader = () => {
  return (
    <Link to="/" className="text-decoration-none navbar-brand text-left">
      <div className="d-flex align-items-center py-3">
        <img src={Logo} alt="Logo" height="35"/>
        <span className="font-sans-serif"/>
      </div>
    </Link>
  )
}