import React from 'react'
import styled from "styled-components";
import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CUTLIO_DATE_FORMAT } from "../../Cutlio.Containers/Application/Contants";
import { useLocalizationContext } from "../../Cutlio.Providers/LocalizationProvider";

interface Props {
  date: string
  disabled?: boolean;
  handleChange: (date: Date) => void
}

export const SidebarDatePicker = ({date, disabled, handleChange}: Props) => {
  const {currentLocale} = useLocalizationContext()

  function getDate() {
    return parse(date, CUTLIO_DATE_FORMAT, new Date())
  }

  function getFormattedDate() {
    const parsed = parse(date, CUTLIO_DATE_FORMAT, new Date())
    return format(parsed, "PP", {locale: currentLocale})
  }

  function getPickerInput() {
    return (
      <StyledHolder>
        <StyledTimeHolder>{getFormattedDate()}</StyledTimeHolder>
        <FontAwesomeIcon icon="chevron-down" className="ps-2 text-600"/>
      </StyledHolder>
    )
  }

  return (
    <DatePicker
      disabled={!!disabled}
      calendarStartDay={1}
      selected={getDate()}
      onChange={handleChange}
      customInput={getPickerInput()}
    />
  )
}

const StyledHolder = styled.h6`
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
`

const StyledTimeHolder = styled.span`
  text-transform: capitalize;
`
