import { gql, useMutation } from "@apollo/client";
import { IPartnerCreateAppointmentRequest, IPartnerCreateAppointmentResponse } from "../Types";

export const CREATE_APPOINTMENT_MUTATION = gql`
    mutation CreateAppointmentMutation($createRequest: CreateAppointmentCommandDtoInput!) {
        createAppointment(input: {request: {input: $createRequest}}) {
            partnerAppointmentPayload {
                response {
                    id
                }
            }
        }
    }
`

/**
 * Use create appointment mutation
 */
export const useCreateAppointmentMutation = () =>
  useMutation<IPartnerCreateAppointmentResponse, IPartnerCreateAppointmentRequest>(
    CREATE_APPOINTMENT_MUTATION, {
      fetchPolicy: 'network-only',
    });