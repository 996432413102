import * as Locales from 'date-fns/locale'
import { LocalizationEnum } from "../Enums";

import enUSTranslation from '../../../Cutlio.Locales/Resources/en-US/translation.json';
import enUSValidation from '../../../Cutlio.Locales/Resources/en-US/validation.translation.json';

import bsDefaultValidation from '../../../Cutlio.Locales/Resources/bs-Latn-BS/translation.json';
import bsDefaultTranslation from '../../../Cutlio.Locales/Resources/bs-Latn-BS/validation.translation.json';

import hrDefaultValidation from '../../../Cutlio.Locales/Resources/hr-HR/translation.json';
import hrDefaultTranslation from '../../../Cutlio.Locales/Resources/hr-HR/validation.translation.json';

import srLatRSDefaultValidation from '../../../Cutlio.Locales/Resources/sr-Latn-RS/translation.json';
import srLatRSDefaultTranslation from '../../../Cutlio.Locales/Resources/sr-Latn-RS/validation.translation.json';

/**
 * Maps language to locale
 * @param language
 */
export function getLocaleForGivenLanguage(language: LocalizationEnum) {
  switch (language) {
    case LocalizationEnum.BosnianDefault:
      return Locales.bs;
    case LocalizationEnum.SerbianDefault:
      return Locales.srLatn;
    case LocalizationEnum.CroatianDefault:
      return Locales.hr;
    case LocalizationEnum.MacedonianDefault:
      return Locales.mk;
    case LocalizationEnum.EnglishUnitedStated:
      return Locales.enUS;
    case LocalizationEnum.GermanDefault:
      return Locales.de;
    case LocalizationEnum.MontenegroLatin:
      return Locales.srLatn;
    case LocalizationEnum.GermanAustria:
      return Locales.deAT;
    default:
      return Locales.enUS;
  }
}

export function getTranslationForGivenLanguage(language: LocalizationEnum) {
  switch (language) {
    case LocalizationEnum.EnglishUnitedStated:
      return {...enUSTranslation, ...enUSValidation};
    case LocalizationEnum.CroatianDefault:
      return {...hrDefaultTranslation, ...hrDefaultValidation};
    case LocalizationEnum.BosnianDefault:
      return {...bsDefaultTranslation, ...bsDefaultValidation};
    case LocalizationEnum.SerbianDefault:
      return {...srLatRSDefaultTranslation, ...srLatRSDefaultValidation};
    case LocalizationEnum.MontenegroLatin:
      return {...srLatRSDefaultTranslation, ...srLatRSDefaultValidation};
    default:
      return {...enUSTranslation, ...enUSValidation};
  }
}