import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setPreventFetch } from "../../Redux";

interface Props {
  show: boolean
}

export const SelectClientButton = ({show}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const placeholder = intl.formatMessage({id: "appointment.details.select.client.placeholder"});

  function handleClick() {
    dispatch(setPreventFetch(true))
    navigate('/client')
  }

  return (
    <StyledButtonHolder hidden={!show} onClick={handleClick} as="div">
      <StyledPlaceholder>
        <StyledIcon icon="plus"/>
      </StyledPlaceholder>
      <StyledLabel>{placeholder}</StyledLabel>
    </StyledButtonHolder>
  )
}

const StyledButtonHolder = styled(Container)`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-left: .5rem;
  align-items: center;
  flex-direction: row;
`

const StyledPlaceholder = styled.span`
  display: flex;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--falcon-gray-600);
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--falcon-gray-600);
`

const StyledLabel = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-size: .9rem;
  padding-left: .5rem;
  color: var(--falcon-gray-600);
`