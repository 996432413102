import { gql, useQuery } from "@apollo/client";
import { IWorkScheduleDayRequest, IWorkScheduleDayResponse } from "../Types";

export const GET_WORK_SCHEDULE_QUERY = gql`
    query GetWorkScheduleDayQuery($employeeId: Int!, $selectedDate: Date!) {
        employeeDaySchedule(input: { employeeId: $employeeId, selectedDate: $selectedDate }) {
            response {
                date
                endTime
                startTime
                employeeId
                breaks {
                    breakEnd
                    duration
                    breakStart
                }
            }
        }
    }
`

/**
 * Use  query
 * @param employeeId
 * @param selectedDate
 * @param skip
 * @param onCompleted
 */
export const useGetWorkSchedule = (employeeId: number, selectedDate: string, skip: boolean, onCompleted: (response: IWorkScheduleDayResponse) => void) =>
  useQuery<IWorkScheduleDayResponse, IWorkScheduleDayRequest>(GET_WORK_SCHEDULE_QUERY, {
      variables: {employeeId, selectedDate},
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: 'network-only',
      fetchPolicy: 'network-only',
      onCompleted: onCompleted,
      skip: skip,
    }
  );
