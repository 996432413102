import React from 'react'
import Avatar from "react-avatar";
import styled from "styled-components";
import { OptionProps } from 'react-select'
import { FormattedMessage, useIntl } from "react-intl";
import { IServiceListItem } from "../Types";
import { useServicePriceType } from "../Hooks/ServicePriceType";


export const SelectServiceOptionItem = ({data, innerRef, innerProps}: OptionProps<IServiceListItem>) => {
  const intl = useIntl()
  const priceTypes = useServicePriceType(data.priceTypeEnum)

  return (
    <StyledContainer className="fs-10" ref={innerRef} {...innerProps}>
      <StyledAvatarHolder>
        <Avatar
          round
          size="2.75rem"
          maxInitials={2}
          src={data.servicePhoto}
          name={data.serviceName}
          className="avatar avatar-3xl cursor-pointer"
        />
      </StyledAvatarHolder>
      <StyledContentHolder>
        <StyledServiceName className="text-truncate">{data.serviceName}</StyledServiceName>
        <StyledTitle>{priceTypes}</StyledTitle>
        <StyledServicePrice>
          <FormattedMessage id="service.list.service.price"/>:
          <StyledServicePriceLabel>{data.price} {data.currency}</StyledServicePriceLabel>
        </StyledServicePrice>
        <StyledServiceDuration>
          <FormattedMessage id="service.list.service.duration"/>:
          <StyledServiceDurationLabel>{data.estimatedDuration} {intl.formatMessage({id: "common.form.element.minutes"})}</StyledServiceDurationLabel>
        </StyledServiceDuration>
        <StyledDivider>&nbsp;</StyledDivider>
      </StyledContentHolder>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  cursor: pointer;
  max-width: 100%;
  overflow-x: hidden;
  padding-right: 1rem;
  padding-left: .75rem;
  padding-top: 1.25rem;

  &:hover {
    background: var(--falcon-gray-200);
  }
`

const StyledAvatarHolder = styled.div`
`

const StyledContentHolder = styled.div`
  flex: 1;
  margin-left: .5rem;
  align-content: center;
`

const StyledServiceName = styled.span`
  font-weight: 600;
  display: block;
  width: calc(85%);
  max-width: calc(85%);
  font-size: .833333333rem;
  color: var(--falcon-gray-600);
`

const StyledTitle = styled.div`
  font-size: .8rem;
  margin-top: -3px;
  font-style: italic;
  margin-bottom: .5rem;
  color: var(--falcon-gray-600);
`

const StyledServicePrice = styled.div`
  margin-bottom: 0;
  color: var(--falcon-gray-600);
`

const StyledServicePriceLabel = styled.span`
  font-weight: 600;
  padding-left: .25rem;
  padding-right: .25rem;
  color: var(--falcon-gray-600);
`

const StyledServiceDuration = styled.span`
  margin-bottom: 0;
`

const StyledServiceDurationLabel = styled.span`
  font-weight: 600;
  padding-left: .25rem;
  padding-right: .25rem;
  color: var(--falcon-gray-600);
`

const StyledDivider = styled.div`
  border-bottom: 1px dashed var(--falcon-border-color);
`
