import { EventContentArg } from "@fullcalendar/core";
import { IEventStyles } from "../Types";
import { EAppointmentStatusEnum } from "../Enums";

export function useEventStyles(eventInfo: EventContentArg): IEventStyles {
  const {event} = eventInfo;
  const {borderColor, backgroundColor} = event;
  const defaultStyle = {backgroundColor, borderLeftColor: borderColor, color: borderColor};

  function getNotShowUpStatusColors() {
    const textColor = "var(--falcon-gray-700)";
    const backgroundColor = "var(--falcon-gray-200)";
    const borderLeftColor = "var(--falcon-danger)";
    return {backgroundColor, borderLeftColor, color: textColor}
  }

  function getBreakStatusColors() {
    const textColor = "var(--falcon-gray-200)";
    const borderLeftColor = "var(--falcon-gray-600)";
    const backgroundColor = "var(--falcon-gray-500)";
    return {backgroundColor, borderLeftColor, color: textColor}
  }

  function getDoneStatusColors() {
    const textColor = "var(--falcon-gray-600)";
    const backgroundColor = "var(--falcon-gray-200)";
    const borderLeftColor = "var(--falcon-gray-500)";
    return {backgroundColor, borderLeftColor, color: textColor}
  }

  function getInlineAddColors() {
    const textColor = "var(--falcon-gray-800)"
    const backgroundColor = "var(--falcon-white)"
    const borderLeftColor = "var(--falcon-gray-1000)"
    const border = ".1rem dashed var(--falcon-gray-1000)"
    const borderLeft = ".25rem solid var(--falcon-gray-1000)"
    return {backgroundColor, color: textColor, border, borderLeftColor, borderLeft}
  }


  if (!event?.extendedProps?.status) return getInlineAddColors()
  if (event.extendedProps.status === EAppointmentStatusEnum.CONFIRMED) return defaultStyle;
  if (event.extendedProps.status === EAppointmentStatusEnum.DONE) return getDoneStatusColors()
  if (event.extendedProps.status === EAppointmentStatusEnum.FULLY_PAID) return getDoneStatusColors()
  if (event.extendedProps.status === EAppointmentStatusEnum.NO_SHOW) return getNotShowUpStatusColors()
  if (event.extendedProps.status === EAppointmentStatusEnum.TIME_RESERVATION) return getBreakStatusColors()

  return defaultStyle;
}