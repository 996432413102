import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginContainer } from "../../../Cutlio.Containers/Login/Container/LoginContainer";
import styled from "styled-components";


export const GuestLayout = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center py-5">
        <StyledRow xs={12} sm={12} md={10} lg={8} xl={6} xxl={3}>
          <Routes>
            <Route path="/" element={<LoginContainer/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </StyledRow>
      </Row>
    </Container>
  )
}

const StyledRow = styled(Col)`
  @media (min-width: 1200px) {
    min-width: 640px;
    max-width: 640px;
  }
`