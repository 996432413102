import React from 'react'
import cn from 'classnames'
import { Button, ButtonProps } from "react-bootstrap";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props extends ButtonProps {
  title: string
  isBusy?: boolean
}

export const PrimaryButton = ({isBusy, title, ...rest}: Props) => {
  return (
    <Button
      {...rest}
      color="primary"
      disabled={isBusy || rest.disabled}
      className={cn("rounded-3", rest.className)}>
      {!isBusy ? title : <LoadingIndicator/>}
    </Button>
  )
}
