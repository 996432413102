import { connect } from "react-redux";
import React, { PropsWithChildren } from "react";
import { onError } from "@apollo/client/link/error";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, NextLink, Operation } from '@apollo/client';
import { CutlioCache } from "../InMemoryCache";
import { IRootState } from "../ApplicationState";
import { IRefreshToken } from "../../Cutlio.Containers/Application/Types";
import { applicationSignOut, setRefreshTokenResponse } from "../../Cutlio.Containers/Application/Redux";

const applicationUrl = process.env.REACT_APP_API_URL;

interface Props extends PropsWithChildren {
  accessToken: string;
  refreshToken: string;
  deviceIdentifier: string
  currentLanguage: string | null;

  applicationSignOut(): void;

  setRefreshTokenResponse(response: IRefreshToken): void;
}

class GraphQLProvider extends React.PureComponent<Props> {

  private readonly httpLink: HttpLink;
  private readonly headersLink: ApolloLink;
  private readonly client: ApolloClient<any>;
  private readonly refreshTokenLink: ApolloLink;

  constructor(props: Props) {
    super(props);
    this.headersLink = this.getCutlioHeadersLink();
    this.refreshTokenLink = this.getRefreshTokenLink();

    this.httpLink = new HttpLink({uri: `${applicationUrl}/partner/graphql`})
    const links: Array<ApolloLink> = [this.headersLink, this.httpLink]
    this.client = new ApolloClient({cache: CutlioCache, link: ApolloLink.from(links)});
  }

  getRefreshTokenLink() {
    return onError(({networkError}) => {
      if (!networkError) return;
      if ("response" in networkError) this.handleErrorResponse(networkError.response)
    })
  }

  handleErrorResponse(response: Response) {
    if (response.status !== 401) return;
    this.props.applicationSignOut();
  }

  getCutlioHeadersLink = () => {
    return new ApolloLink(((operation: Operation, forward: NextLink) => {
      operation.setContext(({headers = {}}) => (
        {
          headers: {
            ...headers,
            'Accept-Language': this.props.currentLanguage,
            'X-Device-Identifier': this.props.deviceIdentifier,
            'Authorization': `Bearer ${this.props.accessToken}`,
            'X-Application-Version': '1.0.4'
          }
        }));
      return forward(operation);
    }))
  }

  render() {
    return <ApolloProvider client={this.client}>{this.props.children}</ApolloProvider>;
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    accessToken: state.application.accessToken,
    refreshToken: state.application.refreshToken,
    currentLanguage: state.application.currentLanguage,
    deviceIdentifier: state.application.deviceIdentifier,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({applicationSignOut, setRefreshTokenResponse}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphQLProvider);