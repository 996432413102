import { gql, useQuery } from "@apollo/client";
import { IGetAppointmentsRequest, IGetAppointmentsResponse } from "../Types";


export const GET_APPOINTMENTS_QUERY = gql`
    query GetAppointments($locationId: Int!, $selectedDate: Date!) {
        partnerWebAppointments(locationId: $locationId, selectedDate: $selectedDate)@connection(key:"AppointmentList") {
            response {
                id
                end
                date
                start
                title
                hasNote
                allDay
                status
                groupId
                clientName
                serviceId
                resourceId
                borderColor
                branchOfficeId
                backgroundColor
                displayEndTime
                displayStartTime
            }
        }
    }
`

/**
 * Use user locations query
 */
export const useAppointmentsQuery = (locationId: number, selectedDate: string) =>
  useQuery<IGetAppointmentsResponse, IGetAppointmentsRequest>(GET_APPOINTMENTS_QUERY, {
      variables: {selectedDate, locationId},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      skip: locationId === 0,
    }
  );