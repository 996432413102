import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { CalendarDatePicker } from "../../../Cutlio.Common/DatePicker";
import { ButtonIcon } from "../../../Cutlio.Common/Buttons/ButtonIcon";
import { addWeeks } from "date-fns";

interface Props {
  endDate: Date
  startDate: Date

  onDateChanged(date: Date): void
}

export const WorkScheduleHeader = ({endDate, startDate, onDateChanged}: Props) => {
  const intl = useIntl()

  function handleNextIconClick() {
    onDateChanged(addWeeks(startDate, 1))
  }

  function handlePreviousIconClick() {
    onDateChanged(addWeeks(startDate, -1))
  }

  return (
    <StyledWrapper>
      <StyledTitleWrapper xs={4} sm="auto">
        <StyledTitle>{intl.formatMessage({id: "work.schedule.header.title"})}</StyledTitle>
      </StyledTitleWrapper>
      <StyledContentWrapper xs={6} sm="auto">
        <StyledContent>
          <ButtonIcon icon="chevron-left" variant="falcon-default" size="sm" className="py-1 me-1" onClick={handlePreviousIconClick}/>
          <CalendarDatePicker selectedStartDate={startDate} selectedEndDate={endDate} onChange={onDateChanged} isRange={true}/>
          <ButtonIcon icon="chevron-right" variant="falcon-default" size="sm" className="py-1 ms-1" onClick={handleNextIconClick}/>
        </StyledContent>
      </StyledContentWrapper>
      <StyledActionsWrapper xs={6} sm="auto"/>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledTitleWrapper = styled(Col)`
  display: flex;
  padding-right: 0;
  align-items: center;
`

const StyledTitle = styled.h5`
`
const StyledContentWrapper = styled(Col)`
  padding-left: 0;
  text-align: right;
  margin-left: auto;
`

const StyledContent = styled(Col)`
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`

const StyledActionsWrapper = styled(Col)`
  padding-left: 0;
  margin-left: auto;
  text-align: right;
`
