import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateSelectArg } from "@fullcalendar/core";
import React, { PropsWithChildren, useState } from 'react'
import { ReservationSidebarContext } from "../Context";
import { useLocalizationContext } from "../../LocalizationProvider";
import { useCurrentLocation } from "../../LocationsProvider/Context";
import { IExtendedSelectionInfo, IReservationSidebarContext } from "../Types";
import { resetAppointmentState, setAppointmentSelectionInfo } from "../../../Cutlio.Containers/Appointment/Redux";

interface Props extends PropsWithChildren {
}

interface State {
  showSidebar: boolean
  selectionInfo: DateSelectArg | undefined
}

export const ReservationSidebarProvider = ({children}: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {currentLocation} = useCurrentLocation()
  const {currentLocale} = useLocalizationContext()
  const [state, setState] = useState<State>({showSidebar: false, selectionInfo: undefined})

  function toggleSidebar() {
    if (state.showSidebar) handleClosingSidebar()
    setState({...state, showSidebar: !state.showSidebar})
  }

  function handleClosingSidebar() {
    dispatch(resetAppointmentState())
    navigate('/')
  }

  function handleAppointmentSelected(id: string) {
    setState({...state, showSidebar: true})
    navigate(`appointment/${id}`)
  }

  function handleTimeReservationSelected(id: string) {
    setState({...state, showSidebar: true})
    navigate(`blocked-time/${id}`)
  }

  function handleTimeSelection(selectionInfo: DateSelectArg) {
    const selection: IExtendedSelectionInfo = {...selectionInfo, currentLocale, locationId: currentLocation.locationId};
    setState({...state, showSidebar: true, selectionInfo})
    dispatch(setAppointmentSelectionInfo(selection))
    navigate('appointment')
  }

  const values: IReservationSidebarContext = {
    toggleSidebar,
    showSidebar: state.showSidebar,
    selectionInfo: state.selectionInfo,
    onSelectionCompleted: handleTimeSelection,
    openAppointmentDetails: handleAppointmentSelected,
    openBlockedTimeDetails: handleTimeReservationSelected,
  }

  return <ReservationSidebarContext.Provider value={values}>{children}</ReservationSidebarContext.Provider>
}
