import React from 'react'
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { IEmployeeListItem } from "../../Employees/Types";
import { ScheduleEmployeeItem } from "./ScheduleEmployeeItem";
import { IWorkScheduleListItem } from "../Types";
import { WorkScheduleSlotItem } from "./WorkScheduleSlotItem";

interface Props {
  employee: IEmployeeListItem
  schedule: Array<IWorkScheduleListItem>

  onSchedulePress(schedule: IWorkScheduleListItem): void
}

export const WorkScheduleLineItem = ({employee, schedule, onSchedulePress}: Props) => {
  const fullName = `${employee.firstName} ${employee.lastName}`;

  function renderEmployeeScheduleItem(schedule: IWorkScheduleListItem, index: number) {
    return <WorkScheduleSlotItem key={index} scheduleItem={schedule} onSchedulePress={onSchedulePress}/>
  }

  return (
    <StyledContentWrapper>
      <StyledContent>
        <ScheduleEmployeeItem
          name={fullName}
          title={employee.title}
          profilePhoto={employee.profilePhoto}
          canBookOnline={employee.canOnlineBook}
        />
      </StyledContent>
      {schedule.map(renderEmployeeScheduleItem)}
    </StyledContentWrapper>
  )
}

const StyledContentWrapper = styled(Col)`
  display: flex;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px dashed var(--falcon-border-color);
`

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  background: white;
  align-items: center;
  justify-content: center;
`
