import React from 'react'
import { useIntl } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";
import { ButtonIcon } from "../../../Cutlio.Common/Buttons/ButtonIcon";

export const EmployeeListHeader = () => {
  const intl = useIntl()
  const addText = intl.formatMessage({id: "common.form.element.add"});
  
  return (
    <Card.Header className="bg-light">
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5>{intl.formatMessage({id: "employee.list.heading.title"})}</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          <ButtonIcon icon="plus" variant="outline-primary" size="sm">
            <span className="d-none d-sm-inline-block ms-1">{addText}</span>
          </ButtonIcon>
        </Col>
      </Row>
    </Card.Header>
  )
}