import cn from "classnames";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Offcanvas } from "react-bootstrap";
import React, { PropsWithChildren } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends PropsWithChildren {
  title?: string
  isBackMode?: boolean
  toggleCanvas: () => void
}

export const SidebarHeader = ({toggleCanvas, children, isBackMode, title}: Props) => {
  const intl = useIntl()
  const background = !!title ? "" : "bg-light border-bottom"
  const alignItems = !!isBackMode ? "justify-content-start" : "justify-content-end"

  return (
    <StyledHolder className={background}>
      <StyledWrapper>
        <StyledControls className={alignItems}>
          <StyledBackButtonHolder onClick={toggleCanvas} className={cn({"d-none": !isBackMode})}>
            <StyledBackButton icon="arrow-left-long"/>
            <StyledBackLabel>{!!title ? title : intl.formatMessage({id: "common.form.elements.back"})}</StyledBackLabel>
          </StyledBackButtonHolder>
          <StyledCloseButton type="button" onClick={toggleCanvas} className="btn-close" hidden={!!isBackMode}/>
        </StyledControls>
        <StyledContent>{children}</StyledContent>
      </StyledWrapper>
    </StyledHolder>
  )
}

const StyledHolder = styled(Offcanvas.Header)`
  border-color: var(--falcon-gray-200);
`

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledCloseButton = styled.button`
  --falcon-text-opacity: 1;
  color: inherit !important;
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const StyledBackButtonHolder = styled.button`
  border: none;
  display: flex;
  cursor: pointer;
  background: none;
  text-align: center;
  align-items: center;
  flex-direction: row;
  color: var(--falcon-gray-600);

  &:hover {
    color: var(--falcon-gray-500);
  }
`

const StyledBackButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-top: -2px;
  font-size: 1.3rem;
  color: inherit;
`

const StyledBackLabel = styled.h6`
  color: inherit;
  cursor: pointer;
  margin-top: .4rem;
  font-weight: 500;
  font-size: 1.1rem;
  padding-left: .4rem;
`