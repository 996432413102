import React from 'react'
import cn from "classnames";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import Select, { ControlProps, CSSObjectWithLabel, StylesConfig } from "react-select";
import { IServiceListItem } from "../../Types";
import { useEmployeeServicesQuery } from "../../Queries/GetEmployeeServicesQuery";
import { SelectServiceOptionItem } from "../../Components/SelectServiceOptionItem";

interface Props {
  hideLabel?: boolean
  employeeId: number
  locationId: number
  serviceId: number

  onServiceSelected(service: IServiceListItem | null): void
}

export const SelectServiceContainer = ({hideLabel, serviceId, employeeId, locationId, onServiceSelected}: Props) => {
  const intl = useIntl()
  const {loading, data} = useEmployeeServicesQuery(locationId, employeeId)
  const selectedItem = data?.employeeServices.response?.find(service => service.id === serviceId)

  function filterOptions(option: FilterOptionOption<IServiceListItem>, inputValue: string): boolean {
    return option.data.serviceName.toLowerCase().includes(inputValue.toLowerCase())
  }

  function formatOptionLabel(data: IServiceListItem) {
    return <StyledSelectedOption>{data.serviceName}</StyledSelectedOption>
  }

  return (
    <StyledHolder>
      <StyledLabel className={cn({'d-none': !!hideLabel})}>
        <FormattedMessage id="appointment.details.select.service.title"/>
      </StyledLabel>
      <Select
        isLoading={loading}
        value={selectedItem}
        styles={DropdownStyles}
        onChange={onServiceSelected}
        filterOption={filterOptions}
        formatOptionLabel={formatOptionLabel}
        components={{Option: SelectServiceOptionItem}}
        options={data?.employeeServices?.response || []}
        placeholder={intl.formatMessage({id: 'appointment.details.select.service.placeholder'})}
      />
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.label`
  margin-bottom: 7px;
  padding-left: .25rem;
  color: var(--falcon-gray-600);
`

const StyledSelectedOption = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`

const DropdownStyles: StylesConfig<IServiceListItem, false> = {
  control: (provided: CSSObjectWithLabel, state: ControlProps<IServiceListItem>) => {
    return {
      ...provided,
      cursor: "pointer",
      boxShadow: state.isFocused ? "var(--falcon-primary)" : "var(--falcon-gray-300)",
      '&:hover': {
        border: "1px solid var(--falcon-gray-900)"
      },
      border: state.isFocused ? "1px solid var(--falcon-gray-900)" : "1px solid var(--falcon-gray-300)"
    }
  },
  placeholder: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      opacity: .6,
      fontWeight: 500,
      fontStyle: 'italic',
      fontSize: '.844444444444rem',
      color: 'var(--falcon-gray-600)',
    }
  }
}