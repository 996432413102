import { useFormikContext } from "formik";
import { ICutlioError, useApolloError } from "./ApolloNetworkError";

/**
 * Form summary error
 */
export function useFormSummaryError() {
  const {status, isSubmitting} = useFormikContext()
  const error: ICutlioError = useApolloError(status, isSubmitting)
  return error.showError && !!error?.errorMessage ? error.errorMessage! : null
}