import { format } from "date-fns";
import { gql, useQuery } from "@apollo/client";
import { CUTLIO_DATE_FORMAT } from "../../Application/Contants";
import { ILocationWorkScheduleRequest, ILocationWorkScheduleResponse } from "../Types";

export const GET_LOCATION_WORK_SCHEDULE = gql`
    query GetLocationWorkSchedule($locationId: Int!, $startDate: Date!, $endDate: Date!, $showFixedSchedule: Boolean!){
        locationWorkSchedule( locationId: $locationId, startDate: $startDate, endDate: $endDate, showFixedSchedule: $showFixedSchedule ) {
            response{
                day
                date
                endTime
                startTime
                employeeId
                breakDuration
                localizedDate
                localizedEndTime
                localizedStartTime
                localizedDayOfWeek
            }
        }
    }
`

/**
 * Use employees query
 * @param locationId
 * @param startDate
 * @param endDate
 * @param showFixedSchedule
 */
export const useLocationWorkScheduleQuery = (locationId: number, startDate: Date, endDate: Date, showFixedSchedule: boolean) =>
  useQuery<ILocationWorkScheduleResponse, ILocationWorkScheduleRequest>(GET_LOCATION_WORK_SCHEDULE, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {locationId, startDate: format(startDate, CUTLIO_DATE_FORMAT), endDate: format(endDate, CUTLIO_DATE_FORMAT), showFixedSchedule},
    }
  );
