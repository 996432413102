import React from 'react'
import { Outlet } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useReservationSidebarContext } from "../../../../Cutlio.Providers/ReservationSidebarProvider/Context";


export const ReservationSidebar = () => {
  const {showSidebar} = useReservationSidebarContext()

  return (
    <Offcanvas show={showSidebar} placement="end" scroll={false} backdrop>
      <Outlet/>
    </Offcanvas>
  )
}