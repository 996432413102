import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { setDefaultOptions } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationEnum } from "../Enums";
import { LocalizationContext } from "../Context";
import { ILocalizationContext, ILocalizationState } from "../Types";
import { applicationSetLanguage } from "../../../Cutlio.Containers/Application/Redux";
import { getLocaleForGivenLanguage, getTranslationForGivenLanguage } from "../Utilities";

interface Props {
  children: any;
}

export function LocalizationProvider({children}: Props) {
  const dispatch = useDispatch()
  const currentLanguage = useSelector((state: any) => state.application?.currentLanguage || "en-US");
  const currentLocale = getLocaleForGivenLanguage(currentLanguage as LocalizationEnum);
  const [localization, setLocalization] = useState<ILocalizationState>({currentLocale, currentLanguage})

  function setCurrentLanguage(language: LocalizationEnum) {
    dispatch(applicationSetLanguage(language))
    const currentLocale = getLocaleForGivenLanguage(language);
    setDefaultOptions({locale: currentLocale, weekStartsOn: 1});
    setLocalization({currentLocale, currentLanguage: language});
  }

  function getLocalizationContext(): ILocalizationContext {
    const {currentLanguage, currentLocale} = localization;
    return {currentLanguage, currentLocale, setCurrentLanguage: setCurrentLanguage};
  }

  return (
    <IntlProvider locale={currentLanguage} messages={getTranslationForGivenLanguage(currentLanguage)}>
      <LocalizationContext.Provider value={getLocalizationContext()}>{children}</LocalizationContext.Provider>
    </IntlProvider>
  )
}