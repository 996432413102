import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EFullCalendarEnum } from "../Enums";
import { ICalendarResource, ICalendarResourcesState } from "../Types";

const initialState: ICalendarResourcesState = {
  view: EFullCalendarEnum.DEFAULT_VIEW,
  resources: []
}

const _setCalendarResources: CaseReducer<ICalendarResourcesState, PayloadAction<Array<ICalendarResource>>> = (state: ICalendarResourcesState, action: PayloadAction<Array<ICalendarResource>>) => {
  state.resources = action.payload
  state.view = action.payload?.length > 1 ? EFullCalendarEnum.RESOURCE_VIEW : EFullCalendarEnum.DEFAULT_VIEW;
}

const CalendarResource = createSlice({
  name: 'calendar',
  initialState: initialState,
  reducers: {
    setCalendarResources: _setCalendarResources,
  }
})

export const CalendarResourceReducer = CalendarResource.reducer;
export const {setCalendarResources} = CalendarResource.actions