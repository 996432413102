import React from 'react'
import styled from "styled-components";
import { addDays, format, startOfWeek } from "date-fns";
import { WeekDayItem } from '../WeekDayItem';
import { useLocalizationContext } from "../../../../Cutlio.Providers/LocalizationProvider";

interface ContainerProps {
  $show: boolean
}

interface Props {
  show: boolean
  selectedDays: Array<number>

  onWeekDayItemClicked(day: number): void
}


export const WeekDayPicker = ({show, selectedDays, onWeekDayItemClicked}: Props) => {
  const context = useLocalizationContext()
  const options = {locale: context.currentLocale};
  const monday = startOfWeek(new Date(), {weekStartsOn: 1});

  function isSelected(day: number): boolean {
    return selectedDays.includes(day);
  }

  return (
    <StyledContainer $show={show}>
      <WeekDayItem
        id={1}
        selected={isSelected(1)}
        title={format(monday, "EEE", options)}
        onDayItemClick={onWeekDayItemClicked}
      />
      <WeekDayItem
        id={2}
        selected={isSelected(2)}
        onDayItemClick={onWeekDayItemClicked}
        title={format(addDays(monday, 1), "EEE", options)}
      />
      <WeekDayItem
        id={3}
        selected={isSelected(3)}
        onDayItemClick={onWeekDayItemClicked}
        title={format(addDays(monday, 2), "EEE", options)}
      />
      <WeekDayItem
        id={4}
        selected={isSelected(4)}
        onDayItemClick={onWeekDayItemClicked}
        title={format(addDays(monday, 3), "EEE", options)}
      />
      <WeekDayItem
        id={5}
        selected={isSelected(5)}
        onDayItemClick={onWeekDayItemClicked}
        title={format(addDays(monday, 4), "EEE", options)}
      />
      <WeekDayItem
        id={6}
        selected={isSelected(6)}
        onDayItemClick={onWeekDayItemClicked}
        title={format(addDays(monday, 5), "EEE", options)}

      />
      <WeekDayItem
        id={0}
        selected={isSelected(0)}
        onDayItemClick={onWeekDayItemClicked}
        title={format(addDays(monday, 6), "EEE", options)}
      />
    </StyledContainer>
  )
}


const StyledContainer = styled.div<ContainerProps>`
  padding-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  display: ${props => props.$show ? "flex" : "none"};
`