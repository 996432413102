import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  checked: boolean

  toggleCheckbox(): void
}

export const BlockedTimeAllDayToggleButton = ({checked, toggleCheckbox}: Props) => {
  const icon: any = checked ? ["fas", "square-check"] : ["far", "square"];
  const color = checked ? "var(--falcon-blue)" : "var(--falcon-400)";

  return (
    <StyledHolder className="pt-3">
      <StyledLabel className="fw-medium fs-0">
        <FormattedMessage id="blocked.time.set.as.all.day.pause"/>
      </StyledLabel>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        fontSize="1.5em"
        className="cursor-pointer pt-1"
        onClick={toggleCheckbox}
      />
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledLabel = styled.span`
`