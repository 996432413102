import React from 'react';
import ReactDOM from 'react-dom/client';
import { CutlioApplication } from "./Cutlio.Containers";

// Global imports
import './index.css'
import './Cutlio.Config/Icons/index'
import 'react-datepicker/dist/react-datepicker.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CutlioApplication/>
  </React.StrictMode>
);
