import { gql, useMutation } from "@apollo/client";
import { IDeleteBlockedTimeRequest, IDeleteBlockedTimeResponse } from "../Types";

export const DELETE_BLOCKED_TIME_MUTATION = gql`
    mutation DeleteBlockedTimeMutation($id: Int!) {
        deleteBlockedTime(input: { id: $id }) {
            partnerDeleteBlockedTimePayload{
                deleted
            }
        }
    }
`

/**
 * Use delete mutation
 */
export const useDeleteBlockedTimeMutation = () =>
  useMutation<IDeleteBlockedTimeResponse, IDeleteBlockedTimeRequest>(
    DELETE_BLOCKED_TIME_MUTATION, {
      fetchPolicy: 'network-only'
    });