import React from 'react'
import cn from "classnames";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { IWorkScheduleListItem } from "../Types";

interface Props {
  scheduleItem: IWorkScheduleListItem

  onSchedulePress(item: IWorkScheduleListItem): void
}

export const WorkScheduleSlotItem = ({scheduleItem, onSchedulePress}: Props) => {
  const intl = useIntl()

  function handleSchedulePress() {
    onSchedulePress(scheduleItem)
  }

  function renderScheduleBreakItem() {
    if (scheduleItem.breakDuration <= 0) return intl.formatMessage({id: "work.schedule.no.breaks"})
    else return intl.formatMessage({id: "work.schedule.break.duration"}, {minutes: scheduleItem.breakDuration})
  }

  return (
    <StyledContentWrapper onClick={handleSchedulePress}>
      <StyledContent className={cn({'d-none': !scheduleItem.startTime})}>
        <StyledWorkTimeDisplay>
          {scheduleItem.localizedStartTime} - {scheduleItem.localizedEndTime}
        </StyledWorkTimeDisplay>
        <StyledBreakTimeDisplay>{renderScheduleBreakItem()}</StyledBreakTimeDisplay>
      </StyledContent>
      <StyledTextWrapper className={cn({'d-none': !!scheduleItem.startTime})}>
        <FormattedMessage id="work.schedule.item.not.working"/>
      </StyledTextWrapper>
    </StyledContentWrapper>
  )
}

const StyledContentWrapper = styled.div`
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: .8333333333rem;
  background-color: var(--falcon-gray-100);
  border-right: 1px dashed var(--falcon-gray-300);
`

const StyledContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #e0faef;
`

const StyledWorkTimeDisplay = styled.div`
  font-size: 1.2em;
  letter-spacing: .025em;
  font-weight: 700 !important;
  color: var(--falcon-success);
`

const StyledBreakTimeDisplay = styled.div`
  font-size: 1em;
  padding-top: .2em;
  text-align: center;
  font-style: italic;
  font-weight: 300 !important;
  color: var(--falcon-secondary);
`

const StyledTextWrapper = styled.span`
  opacity: .75;
  font-weight: 600;
  font-style: italic;
  font-size: 1.1em;
  color: var(--falcon-gray-600);
`
