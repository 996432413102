import React from 'react'
import cn from "classnames";
import Avatar from "react-avatar";
import styled from "styled-components";
import { IEmployeeListItem } from "../Types";
import { useEmployeePhoneNumber, useEmployeeStatusClassName, useEmployeeTitle } from "../Hooks";

interface Props {
  employee: IEmployeeListItem
}

export const EmployeeListItem = ({employee}: Props) => {
  const title = useEmployeeTitle(employee)
  const status = useEmployeeStatusClassName(employee);
  const phoneNumber = useEmployeePhoneNumber(employee);
  const fullName = `${employee.firstName} ${employee.lastName}`

  return (
    <StyledContainer className="fs-10">
      <Avatar
        round
        size="4em"
        maxInitials={2}
        name={fullName}
        src={employee.profilePhoto}
        className={cn("avatar avatar-3xl cursor-pointer", status)}
      />
      <StyledContentHolder>
        <StyledName>{fullName}</StyledName>
        <StyledTitle>{title}</StyledTitle>
        <StyledPhoneNumber>{phoneNumber}</StyledPhoneNumber>
        <StyledDivider>&nbsp;</StyledDivider>
      </StyledContentHolder>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
`

const StyledContentHolder = styled.div`
  flex: 1;
  margin-left: .5rem;
  align-content: center;
`

const StyledName = styled.h6`
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0;
  color: var(--falcon-tint);

  &:hover {
    color: var(--falcon-tint);
  }
`

const StyledTitle = styled.div`
  margin-bottom: .25rem;
  font-size: .833333333rem;
  color: var(--falcon-gray-600);
`

const StyledDivider = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px dashed var(--falcon-border-color);
`

const StyledPhoneNumber = styled.div`
  margin-bottom: 0;
  color: var(--falcon-gray-1000);
`