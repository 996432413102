import React from 'react'
import styled from "styled-components";

interface Props {
  message: string | null
}

export const FormSummary = ({message}: Props) => {
  return <StyledSummary>{message}</StyledSummary>;
}

const StyledSummary = styled.div`
  font-weight: 600;
  font-size: .9rem;
  text-align: center;
  padding-top: 1rem;
  color: var(--falcon-danger);
`