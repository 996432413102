import { gql, useMutation } from "@apollo/client";
import { IPartnerMissedAppointmentRequest, IPartnerMissedAppointmentResponse } from "../Types";

export const MISSED_APPOINTMENT_MUTATION = gql`
    mutation MissedAppointmentMutation($missedRequest: MissedAppointmentCommandDtoInput!) {
        missedAppointment(input: {request: {input: $missedRequest}}) {
            partnerAppointmentPayload {
                response {
                    id
                }
            }
        }
    }
`

/**
 * Use send notification mutation
 */
export const useMissedAppointmentMutation = () =>
  useMutation<IPartnerMissedAppointmentResponse, IPartnerMissedAppointmentRequest>(
    MISSED_APPOINTMENT_MUTATION, {
      fetchPolicy: 'network-only',
    });