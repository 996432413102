import { combineReducers } from '@reduxjs/toolkit'
import { ApplicationReducer } from "../../Cutlio.Containers/Application/Redux";
import { AppointmentReducer } from "../../Cutlio.Containers/Appointment/Redux";
import { BlockedTimeReducer } from "../../Cutlio.Containers/BlockedTime/Redux";
import { CalendarReducer } from "../../Cutlio.Containers/Calendar/Redux/CalendarState";
import { CalendarResourceReducer } from "../../Cutlio.Containers/Calendar/Redux/CalendarResources";

const RootReducer = combineReducers({
  calendarResource: CalendarResourceReducer,
  application: ApplicationReducer,
  appointment: AppointmentReducer,
  blockedTime: BlockedTimeReducer,
  calendar: CalendarReducer,
});

export type IRootState = ReturnType<typeof RootReducer>;
export { RootReducer }
