import React from 'react'
import styled from "styled-components";
import { useIntl } from "react-intl";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import Select, { ControlProps, CSSObjectWithLabel, StylesConfig } from "react-select";
import { IEmployeeListItem } from "../../Types";
import { useEmployeesQuery } from "../../Query";
import { SelectEmployeeOptionItem } from "../../Components/SelectEmployeeOptionItem";
import { useCurrentLocation } from "../../../../Cutlio.Providers/LocationsProvider/Context";

interface Props {
  employeeId: number

  onEmployeeItemSelected(employee: IEmployeeListItem | null): void
}

export const SelectEmployeeContainer = ({employeeId, onEmployeeItemSelected}: Props) => {
  const intl = useIntl()
  const {currentLocation} = useCurrentLocation()
  const {loading, data} = useEmployeesQuery(currentLocation.locationId)
  const selectedItem = data?.employees.response?.find(employee => employee.id === employeeId)

  function filterOptions(option: FilterOptionOption<IEmployeeListItem>, inputValue: string): boolean {
    return option.data.firstName.toLowerCase().includes(inputValue.toLowerCase())
  }

  function formatOptionLabel(data: IEmployeeListItem) {
    const name = `${data.firstName} ${data.lastName}`
    return <StyledSelectedOption>{name}</StyledSelectedOption>
  }

  return (
    <StyledHolder>
      <Select
        isLoading={loading}
        value={selectedItem}
        styles={DropdownStyles}
        filterOption={filterOptions}
        onChange={onEmployeeItemSelected}
        formatOptionLabel={formatOptionLabel}
        components={{Option: SelectEmployeeOptionItem}}
        options={data?.employees?.response || []}
        placeholder={intl.formatMessage({id: 'blocked.time.select.employee.placeholder'})}
      />
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.label`
  margin-bottom: 7px;
  padding-left: .25rem;
  color: var(--falcon-gray-600);
`

const StyledSelectedOption = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`

const DropdownStyles: StylesConfig<IEmployeeListItem, false> = {
  control: (provided: CSSObjectWithLabel, state: ControlProps<IEmployeeListItem>) => {
    return {
      ...provided,
      cursor: "pointer",
      boxShadow: state.isFocused ? "var(--falcon-primary)" : "var(--falcon-gray-300)",
      '&:hover': {
        border: "1px solid var(--falcon-gray-900)"
      },
      border: state.isFocused ? "1px solid var(--falcon-gray-900)" : "1px solid var(--falcon-gray-300)"
    }
  },
  placeholder: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      opacity: .6,
      fontWeight: 500,
      fontStyle: 'italic',
      fontSize: '.844444444444rem',
      color: 'var(--falcon-gray-600)',
    }
  }
}