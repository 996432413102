import React, { useState } from "react";
import { CutlioLocationContext } from "../Context";
import { useIsAuthenticated } from "../../ApplicationState/Hooks";
import { useUserLocationsQuery } from "../Queries/GetUserLocationsQuery";
import { ICurrentLocationContext, ICurrentLocationItem, IUserLocationListItem, IUserLocationListResponse } from "../Types";

const initialState: ICurrentLocationItem = {
  name: "",
  address: "",
  currency: "",
  companyId: 0,
  locationId: 0,
  coverImage: undefined
}

export function CurrentLocationProvider(props: any) {
  const isAuthenticated = useIsAuthenticated()
  const {loading, data} = useUserLocationsQuery(isAuthenticated, {onCompleted})
  const [currentLocation, setCurrentLocation] = useState<ICurrentLocationItem>(initialState)

  function onCompleted(response: IUserLocationListResponse) {
    const selectedLocation: IUserLocationListItem = response?.userLocations?.response[0] || {} as IUserLocationListItem
    setCurrentLocation({...selectedLocation})
  }

  function getValues(): ICurrentLocationContext {
    return {
      currentLocation,
      setCurrentLocation,
      allLocations: data?.userLocations.response || []
    }
  }

  return <CutlioLocationContext.Provider value={getValues()}>{loading ? null : props.children}</CutlioLocationContext.Provider>
}