import React from 'react'
import styled from "styled-components";
import { useIntl } from "react-intl";

interface Props {
  employeeName: string
}

export const BlockedTimeEmployee = ({employeeName}: Props) => {
  const intl = useIntl()
  const description = intl.formatMessage({id: "blocked.time.sidebar.employee.not.selected"})
  return <StyledEmployeeName>{employeeName || description}</StyledEmployeeName>;
}

const StyledEmployeeName = styled.h5`
  opacity: .75;
  font-weight: 600;
  padding-top: .75rem;
  font-size: 1.25rem;
  color: var(--falcon-gray-600);
`