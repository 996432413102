import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IServiceListItem } from "../../../Service/Types";
import { IRootState } from "../../../../Cutlio.Providers/ApplicationState";
import { TimeRangePicker } from "../../../../Cutlio.Common/TimeRangePicker";
import { useAppointmentServiceTime } from "../../Hooks/AppointmentServiceTime";
import { SelectServiceContainer } from "../../../Service/Container/SelectService/SelectServiceContainer";
import { setAppointmentServiceEndTime, setAppointmentServiceStartTime, setSelectedAppointmentService } from "../../Redux";

interface Props {
  index: number
  showLabel: boolean
}

export const AppointmentService = ({index, showLabel}: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {request} = useSelector((state: IRootState) => state.appointment)
  const endTime = useAppointmentServiceTime(request.date, request.services[index].endTime)
  const startTime = useAppointmentServiceTime(request.date, request.services[index].startTime)

  function handleServiceEndChanged(date: Date) {
    dispatch(setAppointmentServiceEndTime({index, date}))
  }

  function handleServiceStartChanged(date: Date) {
    dispatch(setAppointmentServiceStartTime({index, date}))
  }

  function handleServiceChanged(service: IServiceListItem | null) {
    if (!service) return
    dispatch(setSelectedAppointmentService({...service, index}))
  }

  return (
    <StyledContainer>
      <StyledServiceLabel hidden={!showLabel}>
        {intl.formatMessage({id: "appointment.details.service.number.placeholder"}, {index: index + 1})}
      </StyledServiceLabel>
      <SelectServiceContainer
        hideLabel={true}
        onServiceSelected={handleServiceChanged}
        serviceId={request.services[index].serviceId}
        locationId={request.services[index].locationId}
        employeeId={request.services[index].employeeId}
      />
      <TimeRangePicker
        hideLabels={true}
        endTime={endTime}
        startTime={startTime}
        handleEndChanged={handleServiceEndChanged}
        handleStartChanged={handleServiceStartChanged}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2rem;
`


const StyledServiceLabel = styled.h6`
  opacity: .6;
  font-weight: 600;
  font-size: .95rem;
  font-style: italic;
  padding-bottom: .5rem;
  color: var(--falcon-gray-600);
`