import React from 'react'
import cn from "classnames";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  value: any
  label: string
  isActive: boolean
  className?: string

  onItemClick(item: any): void
}

export const NavigationDropdownItem = ({value, label, isActive, onItemClick, className}: Props) => {

  function handleClick() {
    onItemClick(value)
  }

  return (
    <StyledItemHolder className={className}>
      <StyledDropdownItem className="link-600 fs-10 text-truncate" onClick={handleClick}>{label}</StyledDropdownItem>
      <StyledSelectedIcon icon="check" className={cn({'d-none': !isActive})}/>
    </StyledItemHolder>
  )
}

const StyledItemHolder = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-direction: row;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background: var(--falcon-white);
  justify-content: space-between;
  border-radius: var(--falcon-border-radius);
`

const StyledSelectedIcon = styled(FontAwesomeIcon)`
  top: 25%;
  right: 0.75rem;
  position: absolute;
  color: var(--falcon-gray-600);
`

const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  font-size: .833333333rem;
`