import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons'
import {
  faArrowLeftLong,
  faArrowRight,
  faBan,
  faCalendarDays,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleMinus,
  faEnvelope,
  faFileCircleCheck,
  faFileCircleMinus,
  faHouse,
  faPlus,
  faSearch,
  faSquare,
  faSquareCheck,
  faTrash,
  faUser,
  faUserMinus,
  faUsers,
  faUserSecret,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBan,
  faPlus,
  faCheck,
  faHouse,
  faTrash,
  faUser,
  faUsers,
  faSearch,
  faSquare,
  farSquare,
  faUserTie,
  faEnvelope,
  faUserMinus,
  faUserSecret,
  faArrowRight,
  faCircleMinus,
  faSquareCheck,
  faChevronDown,
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
  faArrowLeftLong,
  faFileCircleMinus,
  faFileCircleCheck,
)