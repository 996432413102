import React from 'react'
import { Row } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  text: string
}

export const SidebarDivider = ({text}: Props) => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledText>{text}</StyledText>
      </StyledWrapper>
    </StyledContainer>
  )
}

const StyledContainer = styled(Row)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

const StyledWrapper = styled.div`
  display: flex;
  background: var(--falcon-gray-200);
`

const StyledText = styled.span`
  opacity: .8;
  font-size: 1rem;
  font-weight: 700;
  padding-top: .35rem;
  padding-bottom: .35rem;
  color: var(--falcon-gray-600);
`
