import { useIntl } from "react-intl";
import { EAppointmentStatusEnum } from "../../Calendar/Enums";

interface Props {
  label: string
  backgroundColor: string
}

export function useAppointmentDisplayStatus(status: EAppointmentStatusEnum): Props {
  const intl = useIntl()

  if (status === EAppointmentStatusEnum.CONFIRMED) return {backgroundColor: "var(--falcon-success)", label: intl.formatMessage({id: "appointment.status.confirmed"})}
  if (status === EAppointmentStatusEnum.DECLINED) return {backgroundColor: "var(--falcon-danger)", label: intl.formatMessage({id: "appointment.status.declined"})}
  if (status === EAppointmentStatusEnum.CANCELED) return {backgroundColor: "var(--falcon-danger)", label: intl.formatMessage({id: "appointment.status.canceled"})}
  if (status === EAppointmentStatusEnum.CLIENT_CANCELED) return {backgroundColor: "var(--falcon-danger)", label: intl.formatMessage({id: "appointment.status.client.canceled"})}
  if (status === EAppointmentStatusEnum.NO_SHOW) return {backgroundColor: "var(--falcon-danger)", label: intl.formatMessage({id: "appointment.status.no.show"})}
  if (status === EAppointmentStatusEnum.DONE) return {backgroundColor: "var(--falcon-gray-500)", label: intl.formatMessage({id: "appointment.status.paid"})}
  if (status === EAppointmentStatusEnum.FULLY_PAID) return {backgroundColor: "var(--falcon-gray-500)", label: intl.formatMessage({id: "appointment.status.fully.paid"})}
  if (status === EAppointmentStatusEnum.TIME_RESERVATION) return {backgroundColor: "var(--falcon-gray-500)", label: intl.formatMessage({id: "appointment.status.time.reservation"})}
  return {backgroundColor: "transparent", label: ""}
}