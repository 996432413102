import { gql, useMutation } from "@apollo/client";
import { IUpdateBlockedTimeRequest, IUpdateBlockedTimeResponse } from "../Types";

export const UPDATE_BLOCKED_TIME_MUTATION = gql`
    mutation UpdateBlockedTimeMutation($request: UpdateBlockedTimeCommandDtoInput!) {
        updateBlockedTime(input: { input: {request: $request} }) {
            partnerUpdateBlockedTimePayload{
                updated
            }
        }
    }
`

/**
 * Use delete mutation
 */
export const useUpdateBlockedTimeMutation = () =>
  useMutation<IUpdateBlockedTimeResponse, IUpdateBlockedTimeRequest>(
    UPDATE_BLOCKED_TIME_MUTATION, {
      fetchPolicy: 'network-only'
    });