import classNames from "classnames";
import { Nav, Navbar } from "react-bootstrap";
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { SearchBox } from "../SearchBox";

interface Props extends PropsWithChildren {
  toggleMenu: () => void
}

export const MainNavigationBar = ({toggleMenu, children}: Props) => {
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  function handleScroll() {
    const element = document.documentElement;
    if (element.scrollTop > 0 && showShadow) return;
    setShowShadow(element.scrollTop > 0);
  }

  const burgerCss = `navbar-toggler-humburger-icon btn btn-link d-flex flex-center d-xxl-none`;
  const css = classNames("navbar-glass fs--1 navbar-top sticky-kit", {'navbar-glass-shadow': showShadow});

  return (
    <Navbar className={css} expand="xxl">
      <Navbar.Toggle className="toggle-icon-wrapper me-md-3 me-2" as="div" aria-controls="responsive-navbar-nav">
        <button className={burgerCss} id="burgerMenu" onClick={toggleMenu}>
          <span className="navbar-toggle-icon"><span className="toggle-line"/></span>
        </button>
      </Navbar.Toggle>
      <Nav navbar className="align-items-center d-none d-lg-block" as="ul"/>
      <Nav navbar className="align-items-center d-none d-lg-block" as="ul">
        <Nav.Item as="li"><SearchBox/></Nav.Item>
      </Nav>
      <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul">{children}</Nav>
    </Navbar>
  )
}
