import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBlockedDate } from "../../../BlockedTime/Redux";
import { ButtonIcon } from "../../../../Cutlio.Common/Buttons/ButtonIcon";
import { IRootState } from "../../../../Cutlio.Providers/ApplicationState";
import { useReservationSidebarContext } from "../../../../Cutlio.Providers/ReservationSidebarProvider/Context";

export const NewBlockedTimeButton = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {toggleSidebar} = useReservationSidebarContext()
  const {formattedStart} = useSelector((state: IRootState) => state.calendar)
  const blockedTimeText = intl.formatMessage({id: "appointment.calendar.toolbar.new.blocked.time"})

  function handleClick() {
    toggleSidebar()
    navigate('/blocked-time')
    dispatch(setSelectedBlockedDate(formattedStart))
  }

  return (
    <StyledButton onClick={handleClick} icon="plus" variant="falcon-primary" size="sm">
      <span className="d-none d-sm-inline-block ms-1">{blockedTimeText}</span>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonIcon)`
`