import React from 'react'
import { Card } from "react-bootstrap";
import { useEmployeesQuery } from "../../Query";
import { IEmployeeListItem } from "../../Types";
import { CardLoader } from "../../../../Cutlio.Common/CardLoader";
import { EmployeeListItem } from "../../Components/EmployeeListItem";
import { EmployeeListHeader } from "../../Components/EmployeeListHeader";
import { useCurrentLocation } from "../../../../Cutlio.Providers/LocationsProvider/Context";

export const EmployeeListContainer = () => {
  const {currentLocation} = useCurrentLocation()
  const {data, loading} = useEmployeesQuery(currentLocation.locationId)
  const employees: Array<IEmployeeListItem> = data?.employees?.response || []

  return (
    <Card>
      <EmployeeListHeader/>
      <Card.Body>
        <CardLoader show={loading}/>
        {employees.map((item: IEmployeeListItem) => <EmployeeListItem employee={item} key={item.id}/>)}
      </Card.Body>
    </Card>
  )
}