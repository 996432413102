import React from 'react'
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { IRootState } from "../../../../Cutlio.Providers/ApplicationState";
import { PrimaryButton } from "../../../../Cutlio.Common/Buttons/PrimaryButton";
import { useDeleteBlockedTimeMutation } from "../../Mutations/DeleteBlockedTimeMutation";
import { GET_APPOINTMENTS_QUERY } from "../../../Calendar/Queries/GetAppointmentsQuery";
import { useCurrentLocation } from "../../../../Cutlio.Providers/LocationsProvider/Context";
import { ICutlioError, useApolloError } from "../../../../Cutlio.Common/Hooks/Error/ApolloNetworkError";
import { useReservationSidebarContext } from "../../../../Cutlio.Providers/ReservationSidebarProvider/Context";

interface Props {
  show: boolean
  onBackClicked: () => void
}

export const BlockedTimeDeleteWarning = ({show, onBackClicked}: Props) => {
  const intl = useIntl()
  const params = useParams()
  const {currentLocation} = useCurrentLocation()
  const {toggleSidebar} = useReservationSidebarContext()
  const {formattedStart} = useSelector((state: IRootState) => state.calendar);
  const [deleteBlockedTime, deleteOptions] = useDeleteBlockedTimeMutation();
  const deleteError: ICutlioError = useApolloError(deleteOptions.error, deleteOptions.loading)

  function getId() {
    return !params?.id ? 0 : Number(params.id)
  }

  function handleSubmit() {
    const refetchVariables = {locationId: currentLocation.locationId, selectedDate: formattedStart};
    const refetchQueries = [{query: GET_APPOINTMENTS_QUERY, variables: refetchVariables}]
    deleteBlockedTime({awaitRefetchQueries: true, variables: {id: getId()}, refetchQueries}).then(handleSuccessDelete).catch();
  }

  function handleSuccessDelete() {
    toggleSidebar()
  }

  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <StyledContainer>
        <StyledWrapper>
          <StyledIcon size={45}/>
          <StyledContent>
            <StyledTitle>{intl.formatMessage({id: "blocked.time.actions.delete.title"})}</StyledTitle>
            <StyledDescription>{intl.formatMessage({id: "blocked.time.actions.delete.placeholder"})}</StyledDescription>
            <StyledError hidden={!deleteError.showError}>{deleteError.errorMessage}</StyledError>
          </StyledContent>
        </StyledWrapper>
      </StyledContainer>
      <StyledFooter>
        <Button className="me-2" size="sm" variant="outline" onClick={onBackClicked}>
          {intl.formatMessage({id: "common.form.elements.back"})}
        </Button>
        <PrimaryButton
          size="sm"
          variant="danger"
          onClick={handleSubmit}
          isBusy={deleteOptions.loading}
          title={intl.formatMessage({id: "blocked.time.delete.button.text"})}
        />
      </StyledFooter>
    </Modal>
  )
}


const StyledContainer = styled(Modal.Body)`
`

const StyledWrapper = styled.div`
  display: flex;
`

const StyledIcon = styled(BsFillExclamationTriangleFill)`
  color: var(--falcon-danger);
`

const StyledContent = styled.div`
  width: 100%;
  padding-top: .5rem;
  padding-left: .75rem;
`

const StyledTitle = styled.h5`
  font-size: 1.2rem;
  font-weight: 600;
`

const StyledFooter = styled(Modal.Footer)`
  border-top: none;
  background: var(--falcon-light);
`

const StyledError = styled.p`
  font-weight: 600;
  font-size: .9rem;
  margin-top: .75rem;
  border-radius: .375rem;
  color: var(--falcon-danger);
`

const StyledDescription = styled.p`
  opacity: .8;
  font-size: .8rem;
  font-weight: 500;
  font-style: italic;
  color: var(--falcon-gray-600);
`
