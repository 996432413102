import React from 'react'
import cn from "classnames";
import Avatar from "react-avatar";
import styled from "styled-components";
import { Col } from "react-bootstrap";

interface Props {
  name: string;
  title?: string;
  profilePhoto?: string;
  canBookOnline: boolean;
}

export const ScheduleEmployeeItem = ({name, canBookOnline, profilePhoto, title}: Props) => {

  function getEmployeeStatus() {
    return canBookOnline ? "status-online" : "status-offline"
  }

  return (
    <StyledContentWrapper>
      <StyledContent>
        <Avatar
          name={name}
          maxInitials={2}
          src={profilePhoto}
          round size="3.25em"
          className={cn("avatar avatar-3xl", getEmployeeStatus())}
        />
      </StyledContent>
      <StyledInformationContent>
        <StyledNameWrapper>
          <StyledNameContent>{name}</StyledNameContent>
        </StyledNameWrapper>
        <StyledTitleWrapper>
          <StyledTitleContent className="fs-10">{title}</StyledTitleContent>
        </StyledTitleWrapper>
      </StyledInformationContent>
    </StyledContentWrapper>
  )
}

const StyledContentWrapper = styled(Col)`
  flex: 1;
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: start;
  border-right: 1px dashed var(--falcon-gray-300);
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
`

const StyledInformationContent = styled.div`
  flex: 1;
  padding-left: .5rem;
  align-content: center;
`

const StyledNameWrapper = styled.h6`
  margin-bottom: 0;
  padding-bottom: 0;
`

const StyledNameContent = styled.span`
  font-size: .95rem;
  margin-bottom: .5rem;
  color: var(--falcon-blue);
`

const StyledTitleWrapper = styled.p`
  margin-top: -.15rem;
  margin-bottom: .5rem;
`

const StyledTitleContent = styled.span`
  color: var(--falcon-gray-700);
`
