import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { IGetBlockedTimeRequest, IGetBlockedTimeResponse } from "../Types";

export const GET_BLOCKED_TIME_QUERY = gql`
    query GetBlockedTimeQuery($id: Int!) {
        blockedTime(id: $id) {
            response {
                id
                date
                endTime
                lastName
                firstName
                startTime
                isAllDay
                employeeId
                locationId
                appointmentId
                description
                profilePhoto
            }
        }
    }
`

/**
 * Use employees query
 * @param id
 * @param options
 */
export const useGetBlockingTimeQuery = (id: number, options?: QueryHookOptions<IGetBlockedTimeResponse, IGetBlockedTimeRequest>) =>
  useQuery<IGetBlockedTimeResponse, IGetBlockedTimeRequest>(GET_BLOCKED_TIME_QUERY, {
      variables: {id},
      skip: !id || id === 0,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  );
