import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { IUserLocationListRequest, IUserLocationListResponse } from "../Types";

export const GET_USER_LOCATIONS = gql`
    query GetUserLocations {
        userLocations {
            response {
                name
                address
                coverImage
                companyId
                currency
                locationId
            }
        }
    }
`

/**
 * Use user locations query
 */
export const useUserLocationsQuery = (isAuthenticated: boolean, options?: QueryHookOptions<IUserLocationListResponse, IUserLocationListRequest>) =>
  useQuery<IUserLocationListResponse, IUserLocationListRequest>(GET_USER_LOCATIONS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      skip: !isAuthenticated,
      ...options,
    }
  );