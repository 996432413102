import { gql, useQuery } from "@apollo/client";
import { IEmployeeListRequest, IEmployeeListResponse } from "../Types";

export const GET_EMPLOYEES_QUERY = gql`
    query GetEmployees($locationId: Int!) {
        employees(locationId: $locationId) {
            response {
                id
                title
                lastName
                firstName
                phoneNumber
                profilePhoto
                canOnlineBook
            }
        }
    }
`

/**
 * Use employees query
 * @param locationId
 */
export const useEmployeesQuery = (locationId: number) =>
  useQuery<IEmployeeListResponse, IEmployeeListRequest>(GET_EMPLOYEES_QUERY, {
      variables: {locationId},
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );