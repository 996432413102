import { IClientListItem } from "../Types";
import { useIntl } from "react-intl";

/**
 * Determines which status to render
 * @param client
 */
export function useClientStatusClassName(client: IClientListItem) {
  if (client.clientStatus === 0) return "status-online"
  if (client.clientStatus === 1) return "status-online"
  if (client.clientStatus === 2) return "status-away"
  if (client.clientStatus === 3) return "status-do-not-disturb"
}


export function useClientPhoneNumber(client: IClientListItem) {
  const intl = useIntl()
  return !!client.phoneNumber ? client.phoneNumber : intl.formatMessage({id: "employee.list.employee.missing.phone.number"});
}