import React from 'react'
import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  label: string
  hidden: boolean
  checked: boolean
  toggleCheckbox: () => void
}

export const AppointmentCheckbox = ({checked, label, hidden, toggleCheckbox}: Props) => {
  const color = checked ? "var(--falcon-blue)" : "var(--falcon-gray-500)";
  const icon: IconProp = checked ? ["fas", "square-check"] : ["far", "square"];

  return (
    <StyledHolder hidden={hidden}>
      <StyledLabel>{label}</StyledLabel>
      <StyledIcon
        icon={icon}
        color={color}
        fontSize="1.5em"
        onClick={toggleCheckbox}
        className="cursor-pointer pt-1"
      />
    </StyledHolder>
  )
}

const StyledHolder = styled.div`
  width: 100%;
  display: flex;
  padding-top: .35rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLabel = styled.span`
  opacity: .9;
  font-weight: 600;
  font-size: .9rem;
  padding-top: .1rem;
  padding-right: .5rem;
  color: var(--falcon-gray-600);
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-top: 1px;
`