import React from "react";
import styled from "styled-components";
import { Col, Nav, Row } from "react-bootstrap";

interface Props {
  name: string
}

export const NavigationDivider = ({name}: Props) => {
  return (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          <StyledName>{name}</StyledName>
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"/>
        </Col>
      </Row>
    </Nav.Item>
  )
}

const StyledName = styled.span`
  color: var(--falcon-gray-600);
`