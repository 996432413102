import { gql, useQuery } from "@apollo/client";
import { IClientsRequest, IClientsResponse } from "../Types";

export const GET_CLIENTS_QUERY = gql`
    query GetClientsQuery($companyId: Int!, $locationId: Int!, $pageSize: Int!, $page: Int!, $clientStatus: Int, $searchValue: String) {
        companyClients(
            page: $page
            pageSize: $pageSize
            companyId: $companyId
            locationId: $locationId,
            clientStatus: $clientStatus,
            searchValue: $searchValue,
        ) @connection(key:"ClientList") {
            id
            response {
                totalPages
                totalItems
                currentPage
                maxPageSize
                hasPreviousPage
                hasNextPage
                data {
                    id
                    name
                    verified
                    companyId
                    phoneNumber
                    profilePhoto
                    clientStatus
                    providerCurrency
                    totalClientRevenue
                    numberOfCompleted
                    numberOfMissedAppointments
                }
            }
        }
    }
`

/**
 * Use user appointments query
 * @param companyId
 * @param locationId
 * @param page
 * @param pageSize
 * @param searchValue
 */
export const useClientListQuery = (companyId: number, locationId: number, page: number, pageSize: number, searchValue: string | null) =>
  useQuery<IClientsResponse, IClientsRequest>(GET_CLIENTS_QUERY, {
      variables: {companyId, locationId, page, pageSize, searchValue},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );