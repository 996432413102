/**
 * Localization enum
 * List of all available cultures can be found at this link
 * https://learn.microsoft.com/en-us/openspecs/windows_protocols/ms-lcid/a9eac961-e77d-41a6-90a5-ce1a8b0cdb9c?redirectedfrom=MSDN
 */
export enum LocalizationEnum {
  BosnianDefault = "bs-Latn-BA",
  MacedonianDefault = "mk-MK",
  CroatianDefault = "hr-HR",
  EnglishUnitedStated = "en-US",
  SerbianDefault = "sr-Latn-RS",
  GermanDefault = "de-DE",
  MontenegroLatin = "sr-Latn-ME",
  GermanAustria = "de-AT",
}