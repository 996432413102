import React from 'react'
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  icon: any;
  label: string;
  navigateTo: string;

  toggleNavigation(): void;
}

export const NavigationItem = ({icon, label, navigateTo, toggleNavigation}: Props) => {
  const navigate = useNavigate()

  function handleClick() {
    navigate(navigateTo)
    toggleNavigation();
  }

  return (
    <Nav.Item as="li" className="nav-link cursor">
      <StyledLink to={navigateTo} className="nav-link" onClick={handleClick}>
        <div className="d-flex align-items-center">
          <span className="nav-link-icon"><FontAwesomeIcon icon={icon}/></span>
          <span className="nav-link-text ps-1">{label}</span>
        </div>
      </StyledLink>
    </Nav.Item>
  )
}

const StyledLink = styled(NavLink)`
  .nav-link-text {
    color: var(--falcon-gray-600);
  }

  svg {
    color: var(--falcon-gray-600);
  }

  &.active {
    .nav-link-text {
      font-weight: 700;
      color: var(--falcon-primary);
    }

    svg {
      color: var(--falcon-primary);
    }
  }
`
