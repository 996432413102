import React from 'react'
import cn from "classnames";
import styled from "styled-components";
import { Button, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocalizationContext } from "../../Cutlio.Providers/LocalizationProvider";

interface LayoutProps {
  $showDeleteIcon: boolean;
}

interface Props {
  endTime: Date
  index?: number;
  startTime: Date
  disabled?: boolean
  hideLabels?: boolean
  showDeleteIcon?: boolean
  handleItemRemoveClick?: (index?: number) => void;
  handleEndChanged: (date: Date, index?: number) => void
  handleStartChanged: (date: Date, index?: number) => void
}

export const TimeRangePicker = ({startTime, index, endTime, handleStartChanged, handleEndChanged, handleItemRemoveClick, hideLabels, disabled, showDeleteIcon}: Props) => {
  const {currentLocale} = useLocalizationContext()

  function onItemRemoveClick() {
    if (!handleItemRemoveClick) return;
    handleItemRemoveClick(index)
  }

  function onEndChanged(date: Date) {
    handleEndChanged(date, index)
  }

  function onStartChanged(date: Date) {
    handleStartChanged(date, index)
  }

  return (
    <StyledRow>
      <StyledItemLeftHolder>
        <StyledLabel className={cn("form-label", {'d-none': !!hideLabels})}>
          <FormattedMessage id="common.form.elements.start.time"/>
        </StyledLabel>
        <DatePicker
          showTimeSelect
          dateFormat="p"
          disabled={disabled}
          timeIntervals={10}
          showTimeSelectOnly
          selected={startTime}
          locale={currentLocale}
          onChange={onStartChanged}
          className='form-control cursor-pointer outline-none'
          onKeyDown={(event: any) => event.preventDefault()}
        />
      </StyledItemLeftHolder>
      <StyledItemRightHolder $showDeleteIcon={!!showDeleteIcon}>
        <StyledLabel className={cn("form-label", {'d-none': !!hideLabels})}>
          <FormattedMessage id="common.form.elements.end.time"/>
        </StyledLabel>
        <StyledRightItemWrapper>
          <DatePicker
            dateFormat="p"
            showTimeSelect
            disabled={disabled}
            showTimeSelectOnly
            timeIntervals={10}
            selected={endTime}
            locale={currentLocale}
            onChange={onEndChanged}
            className='form-control cursor-pointer outline-none'
            onKeyDown={(event: any) => event.preventDefault()}
          />
          <Button hidden={!showDeleteIcon} className="ms-3 px-2 py-1 rounded-2 btn-falcon-default" onClick={onItemRemoveClick}>
            <FontAwesomeIcon icon="trash" className="text-danger"/>
          </Button>
        </StyledRightItemWrapper>
      </StyledItemRightHolder>
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  padding-top: .75rem;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLabel = styled.label`
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: 7px;
  padding-left: .25rem;
  color: var(--falcon-gray-600);
`

const StyledItemLeftHolder = styled.div`
  flex: 1;
  display: flex;
  padding-left: 0;
  padding-right: .25rem;
  flex-direction: column;
`

const StyledItemRightHolder = styled.div<LayoutProps>`
  display: flex;
  padding-right: 0;
  padding-left: .25rem;
  flex-direction: column;
  flex: ${props => props.$showDeleteIcon ? 1.25 : 1};
`

const StyledRightItemWrapper = styled.div`
  display: flex;
  align-items: center;
`