import { useIntl } from "react-intl";
import { EventContentArg } from "@fullcalendar/core";
import { EAppointmentStatusEnum } from "../Enums";

export function useEventClientName(eventInfo: EventContentArg): string {
  const intl = useIntl()
  const breakText = intl.formatMessage({id: "appointment.list.view.event.time.break"})
  if (eventInfo?.event.extendedProps?.status === EAppointmentStatusEnum.TIME_RESERVATION) return breakText;
  return eventInfo?.event?.extendedProps?.clientName || "";
}
