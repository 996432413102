import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { NavigationDropdownItem } from "../../../Cutlio.Common/NavigationDropdownItem";
import { useCurrentLocation } from "../../../Cutlio.Providers/LocationsProvider/Context";
import { ICurrentLocationItem } from "../../../Cutlio.Providers/LocationsProvider/Types";


export const UserLocationsContainer = () => {
  const {allLocations, currentLocation, setCurrentLocation} = useCurrentLocation()

  return (
    <Dropdown navbar as="li" className="pe-2">
      <Dropdown.Toggle bsPrefix="toggle" as="a" className="nav-link dropdown-toggle d-flex align-items-center pe-1 cursor-pointer">
        <span className="text-capitalize">{currentLocation.name}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        {allLocations.map((location: ICurrentLocationItem) =>
          <NavigationDropdownItem
            value={location}
            label={location.name}
            key={location.locationId}
            onItemClick={setCurrentLocation}
            className="cutlio-navigation-bar-width-250 text-capitalize"
            isActive={location.locationId === currentLocation.locationId}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}
